import type { PromptImage } from '@features/prompts/promptInterface';
import type { DialogProps } from '@radix-ui/react-dialog';
import useTab from '@src/hooks/useTab';
import { useAppSelector } from '@src/store';
import { dismissPreview } from '@src/store/features/appSlice';
import type { Tab } from '@src/store/features/tabsSlice';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/Dialog';
import { Icons } from '../../../components/icons';

function PromptView({ ...dialogProps }: DialogProps) {
  const { open } = useTab();
  const { preview } = useAppSelector((state) => ({
    preview: state.app.preview,
  }));
  const dispatch = useDispatch();

  const images = preview?.images
    ?.filter((image: PromptImage) => image.origin === 'Input')
    ?.map((image: PromptImage) => image.url)
    .join(', ');

  const phrase = images ? `${images}, ${preview?.phrase}` : preview?.phrase;

  const handleClose = () => {
    dispatch(dismissPreview());
  };

  return (
    <Dialog open={Boolean(preview)} onOpenChange={handleClose} {...dialogProps}>
      <DialogContent className="py-4 px-6">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-3 ">
            <div className="tracking-normal">{preview?.name}</div>
            <Button
              onClick={() => {
                if (preview) {
                  open(preview as Tab);
                  dispatch(dismissPreview());
                }
              }}
              size="sm"
              variant="outline"
            >
              <Icons.extrnalLink className="mr-2 h-4 w-4" />
              Edit
            </Button>
          </DialogTitle>
        </DialogHeader>
        <div className="px-4 py-3 h-[212px] border border-slate-300 rounded-md overflow-auto text-sm">
          {images && <p className="text-sky-600">{images}</p>}
          <p>{preview?.phrase}</p>
        </div>
        <div className="flex items-center gap-2 flex-wrap">
          {preview?.images
            .filter((img) => img.origin === 'Output')
            ?.map((image) => (
              <img
                src={image.thumbnail}
                alt={image.name}
                key={image.id}
                className="w-24 h-24 rounded-md"
              />
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PromptView;
