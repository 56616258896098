import useTab from '@src/hooks/useTab';
import { Icons } from '../../../components/icons';
import { Button } from '../../../components/ui/Button';

function CreatePromptButton() {
  const { open } = useTab();
  return (
    <Button onClick={() => open()}>
      <Icons.add className="mr-2 h-4 w-4" />
      <span style={{ lineHeight: '24px' }}> New Prompt</span>
    </Button>
  );
}

export default CreatePromptButton;
