import { api } from '@src/store/api';
import {
  setPrompts,
  updateUsedTargetingTools,
} from '@src/store/features/promptSlice';
import { updateTabs } from '@src/store/features/tabsSlice';
import type { ApiResponse } from '@src/types/publicTypes';
import { mapTargetToolToBackendKey } from '@src/utils/targetToolsUtil';
import { getKeyByValue } from '@src/utils/utils';
import _, { noop } from 'lodash';
import type {
  ListPromptData,
  PromptApi,
  Prompt,
  PromptImage,
} from './promptInterface';
import type { Tools } from '@features/layout/dashboardInterface';
import type { RootState } from '@src/store';

export const promptApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listTargetTools: builder.query<string[], void>({
      query: () => '/public/targettools',
    }),
    listPrompts: builder.query<ListPromptData, PromptApi>({
      query: ({
        page = 1,
        filter = '',
        sortField = 'CreateDate',
        sortOrder = 'Descending',
        projectId = '',
        targetTool = 'notSpecified',
      }) => {
        return {
          url: 'prompt/filter',
          method: 'POST',
          body: {
            page,
            count: 100,
            filter,
            sortField,
            sortOrder,
            projectId,
            targetTool: mapTargetToolToBackendKey[targetTool],
          },
        };
      },
      transformResponse: (response: ApiResponse<ListPromptData>) => {
        const result = response.data;
        result.items.forEach((item) => {
          item.targetTool = getKeyByValue(
            mapTargetToolToBackendKey,
            item.targetTool
          ) as Tools;
        });

        return result;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const tools = data.items.map((item) => item.targetTool);

          dispatch(updateUsedTargetingTools(tools));
          dispatch(setPrompts(data.items));
        } catch (error) {
          noop();
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Prompt' as const,
                id,
              })),
              { type: 'Prompt', id: 'LIST' },
            ]
          : [{ type: 'Prompt', id: 'LIST' }],
    }),
    getPrompt: builder.query<ApiResponse<Prompt>, string>({
      query: (id) => `prompt/${id}`,
      providesTags: (result, error, id) => [{ type: 'Prompt', id }],
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const tabs = _.cloneDeep((getState() as RootState).tabs.tabs);
          const index = tabs.findIndex(({ id }) => id === data.data.id);
          tabs[index] = {
            ...data.data,
            selected: true,
            targetTool: getKeyByValue(
              mapTargetToolToBackendKey,
              data.data.targetTool
            ) as Tools,
          };

          dispatch(updateTabs(tabs));
        } catch (error) {
          noop();
        }
      },
    }),
    findPromptInfo: builder.mutation<Prompt, string>({
      query: (id) => `prompt/${id}`,
      transformResponse: (response: ApiResponse<Prompt>) => ({
        ...response.data,
        targetTool: getKeyByValue(
          mapTargetToolToBackendKey,
          response.data.targetTool
        ) as Tools,
      }),
    }),
    getPromptFullPhrase: builder.mutation<string, string>({
      query: (id) => `prompt/${id}`,
      transformResponse: (response: ApiResponse<Prompt>) => {
        const images = response.data?.images
          ?.map((image: PromptImage) => image.url)
          .join(', ');

        return images
          ? `${images}, ${response.data?.phrase}`
          : response.data?.phrase;
      },
    }),
    updatePromptProject: builder.mutation<
      ApiResponse<Prompt>,
      { promptId: string; projectId: string }
    >({
      query: ({ projectId, promptId }) => ({
        url: `project/${projectId}/prompt/${promptId}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { promptId, projectId }) => [
        { type: 'Prompt' },
      ],
    }),
    updateTool: builder.mutation<
      ApiResponse<Prompt>,
      { id: string; targetTool: string }
    >({
      query: ({ ...patch }) => ({
        url: 'prompt/targettools',
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          promptApi.util.updateQueryData('getPrompt', id, (draft) => {
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Prompt', id }],
    }),
    updatePrompt: builder.mutation<
      ApiResponse<Prompt>,
      Partial<Prompt> & Pick<Prompt, 'id'>
    >({
      query: ({ ...patch }) => ({
        url: 'prompt',
        method: 'PUT',
        body: patch,
        responseHandler: (response) => response.text(),
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          promptApi.util.updateQueryData('getPrompt', id, (draft) => {
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Prompt', id }],
    }),
    deletePrompt: builder.mutation<{ success: boolean; id: number }, string>({
      query(id) {
        return {
          url: `prompt/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Prompt', id: 'LIST' }],
    }),
    addPrompt: builder.mutation<void, Partial<Prompt>>({
      query(body) {
        return {
          url: 'prompt',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Prompt'],
    }),
    deleteGroupOfPrompts: builder.mutation<void, string[]>({
      query(body) {
        return {
          url: 'prompt',
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['Prompt'],
    }),
    duplicatePrompt: builder.mutation<void, string>({
      query(id) {
        return {
          url: `prompt/duplicate/${id}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Prompt'],
    }),
    removeProjectFromPrompt: builder.mutation<void, string>({
      query(id) {
        return {
          url: `project/prompt/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Prompt'],
    }),
    removeProjectFromAllPrompts: builder.mutation<void, string[]>({
      query(prompts) {
        return {
          url: 'project/prompt',
          method: 'DELETE',
          body: prompts,
        };
      },
      invalidatesTags: ['Prompt'],
    }),
  }),
});

export const {
  useListTargetToolsQuery,
  useUpdatePromptProjectMutation,
  useUpdateToolMutation,
  useListPromptsQuery,
  useAddPromptMutation,
  useDeletePromptMutation,
  useGetPromptQuery,
  useUpdatePromptMutation,
  useDeleteGroupOfPromptsMutation,
  useGetPromptFullPhraseMutation,
  useDuplicatePromptMutation,
  useFindPromptInfoMutation,
  useRemoveProjectFromPromptMutation,
  useRemoveProjectFromAllPromptsMutation,
} = promptApi;
