import { Icons } from '@src/components/icons';
import { Button } from '@src/components/ui/Button';
import { useAppSelector } from '@src/store';
import { getActiveTab, getPromptPhrase } from '@src/store/features/tabsSlice';
import useClipboard from 'react-use-clipboard';

const CopyButton = () => {
  const activeTab = useAppSelector(getActiveTab);
  const promptPhrase = useAppSelector(getPromptPhrase);
  const [isCopied, setIsCopied] = useClipboard(promptPhrase, {
    successDuration: 1000,
  });

  if (!activeTab) return null;

  return (
    <Button
      disabled={activeTab.phrase === ''}
      className="gap-2"
      onClick={setIsCopied}
      size="sm"
      variant="white"
    >
      {isCopied ? (
        <>
          <Icons.check className="w-5" />
          <span>Copied!</span>
        </>
      ) : (
        <>
          <Icons.copy className="w-5" />
          <span className="shrink-0">Copy</span>
        </>
      )}
    </Button>
  );
};

export default CopyButton;
