import {
  useGetImageAssetsQuery,
  useUploadNewAssetMutation,
} from '@features/prompts/assetApi';
import useModifyPrompt from '@features/prompts/hooks/useModifyPrompt';
import ThumbnailImage from '@features/prompts/components/ThumbnailImage';
import ThumbnailSkeleton from '@features/prompts/components/ThumbnailSkeleton';
import Spinner from '@src/components/ui/Spinner';
import { showToast } from '@src/plugins/toast';
import { useAppSelector } from '@src/store';
import { getActiveTab, isBlankPrompt, updateEnableTabs } from '@src/store/features/tabsSlice';
import { inputAcceptedFiles } from '@src/utils/acceptedFiles';
import { cn } from '@src/utils/utils';
import { useEffect, useState, type ChangeEvent } from 'react';
import { getIcon } from '@src/components/icons';
import { useDispatch } from 'react-redux';

const OutputImages = () => {
  const activeTab = useAppSelector(getActiveTab);
  const isBlank = useAppSelector(isBlankPrompt);
  const { data: images, isLoading } = useGetImageAssetsQuery(
    activeTab?.id || ''
  );
  const { modify } = useModifyPrompt();
  const [recentlyAddedImage, setRecentlyAddedImage] = useState(null);
  const dispatch = useDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setRecentlyAddedImage(null);
  }, [images?.length]);

  const Plus = getIcon('add');
  const hasImage =
    images &&
    images.length > 0 &&
    images.some(({ origin }) => origin === 'Output');
  const [uploadAsset, { isLoading: uploadLoading }] =
    useUploadNewAssetMutation();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;

    if (activeTab?.id === undefined) return;

    setRecentlyAddedImage({
      url: URL.createObjectURL(files?.[0]),
      origin: 'Input',
      name: files?.[0].name,
    });
    dispatch(updateEnableTabs(false));

    try {
      if (isBlank) {
        await modify({ ...activeTab, name: 'Untitled prompt' });
      }
      await uploadAsset({
        promptId: activeTab?.id || '',
        origin: 'Output',
        file: files?.[0] as File,
      }).unwrap();

      showToast('success', 'File uploaded successfully');
    } catch {
      setRecentlyAddedImage(null);
      showToast('error', 'Failed to upload file');
    }
    finally{
      dispatch(updateEnableTabs(true));
    }
  };

  if (!activeTab) return null;

  return (
    <div className="bg-white rounded-md px-5 py-4 mb-3 mt-8 flex flex-col min-h-[208px]">
      <div
        className={cn(
          'flex items-center justify-between text-base mb-4',
          hasImage ? 'mb-4' : 'mb-0'
        )}
      >
        <div className="text-slate-900 flex items-center gap-2">
          <p>Generated items</p>
          {uploadLoading && <Spinner className="w-4" />}
        </div>
        <label htmlFor="output-item">
          <p className="text-blue-700 cursor-pointer">
            {' '}
            <Plus /> Upload
          </p>
          <input
            id="output-item"
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept={inputAcceptedFiles}
          />
        </label>
      </div>
      <div
        className={cn(
          'grow',
          hasImage || isLoading || recentlyAddedImage
            ? ''
            : 'flex items-center justify-center'
        )}
      >
        {isLoading ? (
          <div className="flex items-center gap-2 flex-wrap">
            {Array(4)
              .fill(0)
              .map((_, i) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <ThumbnailSkeleton key={i} />
              ))}
          </div>
        ) : hasImage || recentlyAddedImage ? (
          <div
            className="flex items-center gap-2 flex-wrap"
            key={images.length}
          >
            {images
              .filter(({ origin }) => origin === 'Output')
              .map(({ url, thumbnail, id, description, name }) => (
                <ThumbnailImage
                  key={id}
                  src={url}
                  alt={description}
                  thumbnail={thumbnail}
                  className="rounded-md me-2"
                  name={name}
                  id={id}
                  size="medium"
                />
              ))}
            {recentlyAddedImage && (
              <ThumbnailImage
                src={recentlyAddedImage.url}
                alt={recentlyAddedImage.name}
                thumbnail={recentlyAddedImage.url}
                className="rounded-md me-2 opacity-15 size-full mt-4"
                name={recentlyAddedImage.name}
                id={recentlyAddedImage.name}
                size="medium"
              />
            )}
          </div>
        ) : (
          <span className="text-gray-300">No generated items</span>
        )}
      </div>
    </div>
  );
};

export default OutputImages;
