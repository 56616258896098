import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@src/components/ui/DropdownMenu';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button } from '@src/components/ui/Button';
import ConfirmDelete from '@src/components/ui/ConfirmDelete';
import useDisclosure from '@src/hooks/useDisclosure';
import { noop } from 'lodash';
import { useDeleteProjectMutation } from '../projectsApi';
import EditProject from './EditProject';
import { useNavigate } from 'react-router-dom';
import { showToast } from '@src/plugins/toast';

type ProjectEditDialogProps = {
  title: string;
  projectId: string;
};

function ProjectEditDialog(props: ProjectEditDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: openConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();
  const [remove] = useDeleteProjectMutation();
  const navigate = useNavigate();

  const deleteProject = async () => {
    try {
      await remove(props.projectId).unwrap();
      showToast('success', 'Project deleted successfully');
      navigate('/projects');
    } catch (error) {
      noop();
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="p-0">
          <DropdownMenuItem
            onSelect={onOpen}
            className="pl-8 focus:bg-accent focus:text-accent-foreground"
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={onOpenConfirmDelete}
            className="pl-8 text-red-600 focus:bg-accent"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <EditProject {...props} isOpen={isOpen} onClose={onClose} />
      <ConfirmDelete
        title="Delete project"
        description="Are you sure you want to delete this project?"
        isOpen={openConfirmDelete}
        onClose={onCloseConfirmDelete}
        onConfirm={deleteProject}
      />
    </>
  );
}

export type { ProjectEditDialogProps };
export default ProjectEditDialog;
