import { useState } from 'react';
import ChooseProject from './ChooseProject';
import NewProject from './NewProject';
import { Dialog, DialogContent } from '@src/components/ui/Dialog';

function MoveToProject({
  onClose,
  promptGroupList,
  id,
  isOpen,
}: {
  onClose: () => void;
  promptGroupList?: string[];
  id?: string;
  isOpen: boolean;
}) {
  const [view, setView] = useState<'new' | 'choose'>('choose');

  const component =
    view === 'choose' ? (
      <ChooseProject
        promptId={id}
        promptGroupList={promptGroupList}
        onViewChange={()=>setView('new')}
        onClose={onClose}
      />
    ) : (
      <NewProject onClose={()=>setView('choose')} />
    );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="prompt-area sm:max-w-[425px]">
        {component}
      </DialogContent>
    </Dialog>
  );
}

export default MoveToProject;
