import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGoogleLoginMutation } from '../accountApi';
import { setCookie } from '@src/utils/cookieUtils';
import { noop } from 'lodash';

const useLoginToken = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams?.get('code') || '';
  const [googleLogin, { isLoading }] = useGoogleLoginMutation();
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (code) login();
  }, []);

  async function login() {
    try {
      const response = await googleLogin(code).unwrap();
      setCookie('token', response.data.accessToken, response.data.expiresIn);
      setCookie(
        'refresh_token',
        response.data.refreshToken,
        response.data.expiresIn
      );
      setCookie('id_token', response.data.idToken, response.data.expiresIn);
      navigate('/');
    } catch (err) {
      noop();
    }
  }

  return { isLoading };
};

export default useLoginToken;
