import { Button } from './Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './Dialog';

type ConfirmDeleteProps = {
  isOpen: boolean;
  description: string;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmDelete = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
}: ConfirmDeleteProps) => {
  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="text-sm text-slate-600s">
        <DialogHeader className="text-lg font-semibold text-primary">
          <DialogTitle> {title}</DialogTitle>
        </DialogHeader>
        {description}
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={onConfirm}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDelete;
