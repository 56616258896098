import PromptCardSkeleton from '@features/prompts/components/prompt-card-skeleton';
import { Skeleton } from '@src/components/ui/Skeleton';
import { random } from 'lodash';

const ProjectSkeleton = () => {
  return (
    <div>
      <Skeleton className="h-6 w-12 mb-2 bg-indigo-200" />
      <Skeleton className="h-6 w-32 mb-4 bg-slate-200" />

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {new Array(5).fill(0).map((_, i) => (
          <PromptCardSkeleton key={random()} />
        ))}
      </div>
    </div>
  );
};

export default ProjectSkeleton;
