import PreviewImage from '@features/prompts/components/PreviewImage';
import { useAppSelector } from '@src/store';
import { getActiveTab, isBlankPrompt } from '@src/store/features/tabsSlice';
import PhraseEditor from './components/PhraseEditor';
import TitleEditor from './components/TitleEditor';
import InputImages from './components/upload/InputImages';
import OutputImages from './components/upload/OutputImages';
import { useGetPromptQuery } from './promptApi';
import { useParams } from 'react-router-dom';
import TargetToolSelection from './components/TargetToolSelection';
import PromptProject from './components/moveToProject/PromptProject';
import PromptSettings from './components/PromptSettings';

function SinglePrompt() {
  const { id } = useParams();
  const activeTab = useAppSelector(getActiveTab);
  const isBlank = useAppSelector(isBlankPrompt);
  document.title = `Prompt ${id} - Image Prompt`;

  // skip the query if the tab is blank or the tab is not found
  useGetPromptQuery(id, { skip: isBlank });

  if (!activeTab) return null;

  return (
    <div className="min-h-[calc(100vh-3.5rem)] flex flex-col" key={id}>
      {/* prompt sidebar */}
      <div className="bg-white">
        <div className="flex justify-center items-start gap-4 px-[90px] lg:px-10 pt-11 pb-5 lg:w-[1100px] lg:mx-auto">
          <TitleEditor />
          <TargetToolSelection />
          <PromptProject />
          {!isBlank && <PromptSettings id={id} />}
        </div>
      </div>

      {/* prompt editor */}
      <main className="bg-slate-100 pt-11 pb-5 grow">
        <div className='grid grid-cols-12 gap-4 lg:w-[1100px] lg:mx-auto px-[90px] lg:px-10'>
          <div className="col-span-7">
            <PhraseEditor />
          </div>

          <div className="col-span-5">
            <InputImages />
            <OutputImages />
          </div>
        </div>
      </main>

      <PreviewImage />
    </div>
  );
}
export default SinglePrompt;
