import { useListPromptsQuery } from '@features/prompts/promptApi';
import CreatePromptButton from '@features/prompts/components/CreatePromptButton';
import PromptView from '@features/prompts/components/PromptView';
import PromptCardList from '@features/prompts/components/PromptCardList';
import { cn } from '@src/utils/utils';
import { useState } from 'react';
import ProjectSkeleton from './components/ProjectSkeleton';
import ProjectEditDialog from './components/UpdateProjectDialog';
import { useGetProjectByIdQuery } from './projectsApi';
import { Icons, getIcon } from '@src/components/icons';
import { useParams } from 'react-router-dom';

type View = 'list' | 'grid';

function ProjectDetailsPage() {
  document.title = 'Project - Image Prompt';
  const { id } = useParams();
  const { isLoading: isProjectLoading, data: projectInfo } =
    useGetProjectByIdQuery(id);
  const {
    isLoading: isPromptsLoading,
    data: promptsList,
    isSuccess,
  } = useListPromptsQuery({ projectId: id });
  const ProjectIcon = getIcon(projectInfo?.data.icon);
  const hasActivePrompts = isSuccess && promptsList?.items.length > 0;

  const [view, setView] = useState<View>('grid');

  if (isProjectLoading || isPromptsLoading) return <ProjectSkeleton />;

  return (
    <div>
      <div className="flex gap-2 mb-2 text-sm">
        {ProjectIcon ? (
          <ProjectIcon className="fill-indigo-600 w-5" />
        ) : (
          <Icons.archive className="stroke-indigo-600 w-5" />
        )}
        <span className="text-indigo-600 ">project</span>
      </div>

      {/* header */}
      <div className="flex justify-between align-center mb-3">
        {/* project name */}
        <div className="flex items-center gap-2">
          <h2 className="text-3xl font-bold tracking-tight">
            {projectInfo.data.name}
          </h2>
          <div className="flex items-center">
            <ProjectEditDialog
              title={projectInfo.data.name}
              projectId={projectInfo.data.id}
            />
          </div>
        </div>

        {/* view switcher */}
        {hasActivePrompts ? (
          <div className="flex gap-2">
            <div className="flex items-center bg-slate-200 h-[42px] w-[82px] p-1.5 rounded-md">
              {['grid', 'list'].map((icon) => {
                const Icon = getIcon(icon);

                return (
                  <div
                    key={icon}
                    onClick={() => setView(icon as View)}
                    className={cn(
                      ' h-full rounded-md cursor-pointer grow p-0 flex items-center justify-center transition-all duration-300',
                      view === icon ? 'bg-white ' : 'transparent'
                    )}
                  >
                    <Icon />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <CreatePromptButton />
        )}
      </div>

      {/* prompt list */}
      {hasActivePrompts ? (
        <PromptCardList view={view} projectId={id} />
      ) : (
        <p className="text-slate-700 text-lg font-semibold mt-2">
          No prompts found
        </p>
      )}

      {/* prompt preview */}
      <PromptView />
    </div>
  );
}

export default ProjectDetailsPage;
