import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import {
  LayoutGrid,
  AlertTriangle,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  CreditCard,
  File,
  FileText,
  HelpCircle,
  Laptop,
  Loader2,
  type LucideProps,
  Moon,
  MoreVertical,
  Settings,
  SunMedium,
  Trash,
  Twitter,
  User,
  History,
  Menu,
  Archive,
  ExternalLink,
  CopyCheck,
  ChevronDown,
  Forward,
  InfoIcon,
  Link,
  Download,
  MoreHorizontal,
  Delete,
  Cross,
  EyeIcon,
  Type,
  X,
} from 'lucide-react';

import type { LucideIcon } from 'lucide-react';
import ChatGpt from './icons/targetingTools/chatgpt';
import Midjourney from './icons/targetingTools/midjourney';
import Dalle from './icons/targetingTools/dalle';
import Book from './icons/projects/book';
import Building from './icons/projects/building';
import Camcorder from './icons/projects/camcorder';
import Camera from './icons/projects/camera';
import Cart from './icons/projects/cart';
import Comments from './icons/projects/comments';
import Food from './icons/projects/food';
import Music from './icons/projects/music';
import Box from './icons/projects/box';
import Magnific from './icons/targetingTools/magnific';
import Freepik from './icons/targetingTools/freepik';
import StableDiffusion from './icons/targetingTools/stable-diffusion';
import Firefly from './icons/targetingTools/firefly';
import { cn } from '@src/utils/utils';

export type Icon = LucideIcon;

type IconProps = {
  className?: string;
};

export const Icons = {
  home: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-house', className)} />
  ),
  penCircle: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-pen-circle', className)} />
  ),
  close: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-x', className)} />
  ),
  add: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-plus', className)} />
  ),
  check: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-check', className)} />
  ),
  copy: ({ className }: IconProps) => (
    <i className={cn('fa-light fa-copy', className)} />
  ),
  image: ({ className }: IconProps) => (
    <i className={cn('fa-regular fa-image', className)} />
  ),
  signout: ({ className }: IconProps) => (
    <i className={cn('fa-regular fa-sign-out', className)} />
  ),
  userCircle: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-user-circle', className)} />
  ),
  clipboard: ({ className }: IconProps) => (
    <i className={cn('fa-regular fa-clipboard', className)} />
  ),
  list: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-list', className)} />
  ),
  grid: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-grid', className)} />
  ),
  gear: ({ className }: IconProps) => (
    <i className={cn('fa-thin fa-gear', className)} />
  ),
  xmark: ({ className }: IconProps) => (
    <i className={cn('fa-solid fa-xmark', className)} />
  ),
  eye: EyeIcon,
  cross: Cross,
  delete: Delete,
  more: MoreHorizontal,
  menu: Menu,
  link: Link,
  download: Download,
  info: InfoIcon,
  spinner: Loader2,
  forward: Forward,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  trash: Trash,
  post: FileText,
  page: File,
  settings: Settings,
  billing: CreditCard,
  ellipsis: MoreVertical,

  warning: AlertTriangle,
  user: User,
  arrowRight: ArrowRight,
  help: HelpCircle,
  sun: SunMedium,
  moon: Moon,
  laptop: Laptop,
  layoutGrid: LayoutGrid,
  history: History,
  archive: Archive,
  extrnalLink: ExternalLink,
  copyCheck: CopyCheck,
  dotsHorizontal: DotsHorizontalIcon,
  x: X,
  type: Type,
  gitHub: ({ ...props }: LucideProps) => (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="github"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 512"
      {...props}
    >
      {/* biome-ignore lint/style/useSelfClosingElements: <explanation> */}
      <path
        fill="currentColor"
        d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
      ></path>
    </svg>
  ),
  twitter: Twitter,
  chatgpt: ({ className }: IconProps) => <ChatGpt className={className} />,
  midjourney: ({ className }: IconProps) => (
    <Midjourney className={className} />
  ),
  magnific: ({ className }: IconProps) => <Magnific className={className} />,
  stableDiffusion: ({ className }: IconProps) => (
    <StableDiffusion className={className} />
  ),
  freepik: ({ className }: IconProps) => <Freepik className={className} />,
  firefly: ({ className }: IconProps) => <Firefly className={className} />,
  dalle: ({ className }: IconProps) => <Dalle className={className} />,
  book: ({ className }: IconProps) => <Book className={className} />,
  building: ({ className }: IconProps) => <Building className={className} />,
  camcorder: ({ className }: IconProps) => <Camcorder className={className} />,
  camera: ({ className }: IconProps) => <Camera className={className} />,
  cart: ({ className }: IconProps) => <Cart className={className} />,
  comments: ({ className }: IconProps) => <Comments className={className} />,
  food: ({ className }: IconProps) => <Food className={className} />,
  music: ({ className }: IconProps) => <Music className={className} />,
  box: ({ className }: IconProps) => <Box className={className} />,
};

export const getIcon = (key = ''): Icon => {
  if (!key || !(key in Icons)) return null;

  return Icons[key as keyof typeof Icons] as Icon;
};
