import { MainNav } from '@features/layout/MainNav';
import { Outlet } from 'react-router-dom';

function PromptLayout() {
  return (
    <div className="flex min-h-screen flex-col">
      <header className="sticky top-0 z-40 border-b bg-primary text-background">
        <div className="pe-6 flex h-14 items-center justify-between">
          <MainNav />
        </div>
      </header>
      <Outlet />
    </div>
  );
}

export default PromptLayout;
