export const createCoveredGhost = (url: string) => {
  const img = document.createElement('img') as HTMLImageElement;
  img.className = 'ghost w-[50px] h-[50px] rounded-md bg-cover';
  img.style.backgroundImage = `url(${url})`;

  return img;
};

export const createColoredGhost = (className: string) => {
  const div = document.createElement('div') as HTMLDivElement;
  div.className = 'ghost w-[50px] h-[50px] rounded-md ' + className;

  return div;
};
