import { setCookie } from '@src/utils/cookieUtils';
import { cn } from '@src/utils/utils';
import { noop } from 'lodash';
import { useLoginMutation } from './accountApi';
import Footer from './components/Footer';
import useLoginToken from './hooks/useLoginToken';
import { useNavigate } from 'react-router-dom';
import Logo from '@assets/svg/logo/logo-white.svg';
import Google from '@assets/svg/logo/google.svg';

const isDevMode = process.env.NODE_ENV === 'development';

export default function AuthenticationPage() {
  const navigate = useNavigate();
  const { isLoading: loginLoading } = useLoginToken();
  const [login, { isLoading: devLoading }] = useLoginMutation();

  const handleDevLogin = async () => {
    try {
      const response = await login({
        email: process.env.NEXT_PUBLIC_DEV_EMAIL ?? 'dev@promptstudio.app',
        password: process.env.NEXT_PUBLIC_DEV_PASSWORD ?? '123123',
      }).unwrap();

      setCookie(
        'token',
        response.data.accessToken,
        Math.floor(response.data.expiresIn / (3600 * 24))
      );
      setCookie(
        'refresh_token',
        response.data.refreshToken,
        response.data.expiresIn
      );
      setCookie('id_token', response.data.idToken, response.data.expiresIn);
      navigate('/');
    } catch (err) {
      noop();
    }
  };

  if (!isDevMode) return;

  return (
    <div className="flex flex-col">
      {/* main  login content */}
      <main className=" bg-slate-200 w-screen h-screen flex justify-center relative">
        <div className="lg:w-[440px] relative top-[20%]">
          <img
            src={Logo}
            alt="Prompt Studio Logo"
            className={cn('mb-20 mx-auto')}
          />
          <div className="bg-slate-300 rounded-lg flex items-center justify-center px-20 py-16">
            <button
              type="button"
              className="bg-white rounded-lg flex items-center justify-center gap-5 px-6 py-3"
              onClick={handleDevLogin}
            >
              <img
                src={Google}
                alt="Google Logo"
                className={
                  devLoading || loginLoading ? 'animate-zoom-play' : ''
                }
              />
              <p className="text-base">Sign in with Google</p>
            </button>
          </div>
        </div>
      </main>

      {/* footer */}
      <Footer />
    </div>
  );
}
