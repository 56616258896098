import type { Tools } from '@features/layout/dashboardInterface';
import { useUpdateToolMutation } from '@features/prompts/promptApi';
import { showToast } from '@src/plugins/toast';
import { useAppSelector } from '@src/store';
import { getActiveTab, updateEnableTabs } from '@src/store/features/tabsSlice';
import { mapTargetToolToBackendKey } from '@src/utils/targetToolsUtil';
import TargetingToolSelect from './TargetingToolSelect';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

function TargetToolSelection() {
  const promptData = useAppSelector(getActiveTab);
  const dispatch = useDispatch();

  const [updateTool , {isLoading}] = useUpdateToolMutation();

  useEffect(() => {
    dispatch(updateEnableTabs(!isLoading))
  }, [isLoading ,dispatch])


  const submit = async (value: Tools) => {
    if (!promptData) return;
    try {
      await updateTool({
        id: promptData.id,
        targetTool: mapTargetToolToBackendKey[value],
      }).unwrap();

      showToast('success', `Target tool updated to ${value}`);
    } catch (error) {
      showToast('error', "You can't choose a target tool for an empty prompt");
    }
  };

  return (
    <div className="flex flex-col gap-2 w-[200px]">
      <span className="text-slate-600 text-sm font-medium">Target tool</span>
      <TargetingToolSelect
        value={promptData?.targetTool}
        onValueChange={submit}
        className="w-[200px]"
      />
    </div>
  );
}
export default TargetToolSelection;
