import { api } from "@src/store/api";
import type { Profile } from "./settingInterface";
import { createSelector } from "@reduxjs/toolkit";

export const settingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<Profile , void>({
      query: () => "/user",
    }),
    updateProfile: builder.mutation<Profile , Partial<Profile>>({
      query: (body) => ({
        url: "/user",
        method: "PUT",
        body,
      }),
    }),
    checkUsername: builder.query<boolean , string>({
      query: (username) => `/user/available/username/${username}`,
    })
  }),

});

export const selectProfile = createSelector(settingApi.endpoints.getProfile.select(), (profile) => profile.data);

export const { useGetProfileQuery , useUpdateProfileMutation , useLazyCheckUsernameQuery } = settingApi;
