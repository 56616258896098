import { Input } from '@src/components/ui/Input';
import { Textarea } from '@src/components/ui/Textarea';
import { showToast } from '@src/plugins/toast';
import { useAppSelector } from '@src/store';
import { useDebounceFn } from 'ahooks';
import { selectProfile, useLazyCheckUsernameQuery, useUpdateProfileMutation } from '../settingApi';
import { Icons } from '@src/components/icons';
import { useState } from 'react';
import type { Profile as ProfileType } from '../settingInterface';
import Spinner from '@src/components/ui/Spinner';

const Profile = () => {
  const profile = useAppSelector(selectProfile);
  const [localProfileState, setLocalProfileState] =useState<ProfileType>(profile);
  const [updateProfile , {isLoading:updateProfileLoading}] = useUpdateProfileMutation();
  const [checkUsername , {isLoading:checkUsernameLoading}] = useLazyCheckUsernameQuery();
  const isLoading = updateProfileLoading || checkUsernameLoading;

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setLocalProfileState((prev) => ({ ...prev, [name]: value
    }));

    if(name==='username') return throttleUsername.run(value);

    throttleSubmit.run();
  };

  const validateUsername = async (username: string) => {
    try {
      await checkUsername(username).unwrap();
      throttleSubmit.run();
    } catch (error) {
      showToast('error', 'Username is already taken');
    }
  };

  const submitChanges = async() => {
   try {
    await updateProfile(localProfileState).unwrap();
    showToast('success', 'Profile updated');
   } catch (error) {
    showToast('error', 'Failed to update profile');
   }
  };

  const throttleSubmit = useDebounceFn(submitChanges, {
    wait: 1000,
  });

  const throttleUsername = useDebounceFn(validateUsername, {
    wait:1000
  });

  return (
    <div className="w-full">
      <div className='flex items-center gap-3'>
      <h2 className="font-semibold text-primary text-lg mb-5">Profile</h2>
      {isLoading && <Spinner/>}
      </div>
      <form className="flex flex-col gap-4 w-1/2 max-w-[600px]">
        <div className="flex gap-6">
          <div className="flex flex-col gap-4 grow">
            <div>
              <label
                htmlFor="username"
                className="mb-1.5 block text-sm text-primary font-medium"
              >
                Username
              </label>
              <Input
                placeholder="username..."
                id="username"
                name="username"
                value={localProfileState.username || localProfileState.name}
                onChange={changeHandler}
                autoComplete="off"
              />
            </div>

            <div>
              <label
                htmlFor="name"
                className="mb-1.5 block text-sm text-primary font-medium"
              >
                Display name
              </label>
              <Input
                placeholder="displayName..."
                id="name"
                name="name"
                value={localProfileState.name}
                onChange={changeHandler}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="size-[124px] shadow-md rounded-md self-end p-3">
            {localProfileState.picture ? <img src={localProfileState.picture} alt={profile.name} className="rounded-full size-full" /> :
            <Icons.userCircle className="fa-2xl" />}
          </div>
        </div>
        <div>
          <label
            htmlFor="bio"
            className="mb-1.5 block text-sm text-primary font-medium"
          >
            Bio
          </label>
          <Textarea
            placeholder="bio..."
            id="bio"
            name="bio"
            value={localProfileState.bio}
            onChange={changeHandler}
            className="text-sm font-normal text-primary"
          />
        </div>
      </form>
    </div>
  );
};

export default Profile;
