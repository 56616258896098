import { Input } from '@src/components/ui/Input';
import { useAppSelector } from '@src/store';
import { getActiveTab } from '@src/store/features/tabsSlice';
import { cn } from '@src/utils/utils';
import { useState, useEffect } from 'react';
import useModifyPrompt from '../hooks/useModifyPrompt';
import Spinner from '@src/components/ui/Spinner';

function TitleEditor() {
  const activeTab = useAppSelector(getActiveTab);
  const { modify, isLoading } = useModifyPrompt();
  const [title, setTitle] = useState(activeTab?.name || '');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setTitle(activeTab?.name || '');
  }, [activeTab]);

  const updateTitle = () => {
    if (!activeTab) return;
    setIsEditing(false);
    modify({ ...activeTab, name: title });
  };

  return (
    <div className=" grow">
      <div className="flex items-center gap-3">
        <p className="text-slate-500 text-base mb-1">Prompt title</p>
        {isLoading && <Spinner className="w-4" />}
      </div>
      <Input
        placeholder="Name your new prompt"
        value={title}
        name="name"
        onBlur={updateTitle}
        onChange={(e) => setTitle(e.target.value)}
        className={cn(
          isEditing
            ? 'border-2'
            : 'border-0 text-slate-900 text-2xl px-0 placeholder-red-300'
        )}
        onClick={() => setIsEditing((prev) => !prev)}
        autoComplete="off"
        readOnly={!isEditing}
      />
    </div>
  );
}
export default TitleEditor;
