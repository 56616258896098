import { Input } from '@src/components/ui/Input';

const Account = () => {
  return (
    <div className="w-full">
      <h2 className="font-semibold text-primary text-lg mb-5">Account</h2>
      <div className=" w-1/2 max-w-[600px]">
        <label
          htmlFor="email"
          className="mb-1.5 block text-sm text-primary font-medium"
        >
          Email
        </label>
        <Input
          id="email"
          name="email"
          value={'pushi@mio.com'}
          autoComplete="off"
          disabled
        />
        <p className="mt-2 text-xs text-slate-500 font-medium">
          Logged in with Google
        </p>
      </div>
      <div className="mt-16 border-t border-slate-200 pt-8">
        <p className="text-primary text-sm font-medium mb-4">
          Delete your account and data
        </p>
        <p className="text-red-700 text-sm font-medium">Delete Account</p>
      </div>
    </div>
  );
};

export default Account;
