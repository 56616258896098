import type { Project } from '../projectInterface';
import { useListProjectsQuery } from '../projectsApi';
import ProjectCard from './ProjectCard';

function ProjectCardList() {
  const { data } = useListProjectsQuery();

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
      {data?.data.map((project: Project) => {
        return <ProjectCard key={project.id} {...project} />;
      })}
    </div>
  );
}

export default ProjectCardList;
