import type { DialogProps } from '@radix-ui/react-dialog';
import { useAppSelector } from '@src/store';
import { dismissImagePreview } from '@src/store/features/appSlice';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/Dialog';

type PreviewImageProps = DialogProps;

const MAX_TITLE_SIZE = 36;
function PreviewImage({ ...dialogProps }: PreviewImageProps) {
  const { imagePreview } = useAppSelector((state) => ({
    imagePreview: state.app.imagePreview,
  }));

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(dismissImagePreview());
  };

  if (!imagePreview) return null;

  return (
    <Dialog
      open={Boolean(imagePreview)}
      onOpenChange={handleClose}
      {...dialogProps}
    >
      <DialogContent className="p-0 ">
        <DialogHeader className="p-6">
          <DialogTitle className="flex items-center gap-4 overflow-hidden text-ellipsis">
            {imagePreview?.name.slice(0, MAX_TITLE_SIZE)}
            {Number(imagePreview?.name.length) > MAX_TITLE_SIZE ? '...' : ''}
          </DialogTitle>
          <img
            className="h-[500px] object-contain w-full"
            src={imagePreview.link}
            alt={imagePreview.alt}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default PreviewImage;
