import PreviewImage from '@features/prompts/components/PreviewImage';
import { useAppSelector } from '@src/store';
import { getActiveTab, isBlankPrompt } from '@src/store/features/tabsSlice';
import PhraseEditor from './components/PhraseEditor';
import PromptAssets from './components/PromptAssets';
import TitleEditor from './components/TitleEditor';
import InputImages from './components/upload/InputImages';
import OutputImages from './components/upload/OutputImages';
import { useGetPromptQuery } from './promptApi';
import { useParams } from 'react-router-dom';

function SinglePrompt() {
  const { id } = useParams();
  const activeTab = useAppSelector(getActiveTab);
  const isBlank = useAppSelector(isBlankPrompt);
  document.title = `Prompt ${id} - Image Prompt`;

  // skip the query if the tab is blank or the tab is not found
  useGetPromptQuery(id, { skip: isBlank });

  if (!activeTab) return null;

  return (
    <div className="min-h-[calc(100vh-3.5rem)] flex flex-col">
      {/* prompt sidebar */}
      <div className="flex justify-between items-start gap-4 px-[90px] pt-11 pb-5 shadow-md sticky top-14 bg-white z-40">
        <TitleEditor />
        <PromptAssets id={id} isBlank={isBlank} />
      </div>

      {/* prompt editor */}
      <main className="grid grid-cols-12 bg-slate-100 gap-4 px-[90px] pt-11 pb-5 grow">
        <div className="col-span-7">
          <PhraseEditor />
        </div>

        <div className="col-span-5">
          <InputImages />
          <OutputImages />
        </div>
      </main>

      <PreviewImage />
    </div>
  );
}
export default SinglePrompt;
