import { getIcon } from '../../../components/icons';
import _ from 'lodash';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/Select';
import type { Tools } from '@features/layout/dashboardInterface';
import { cn } from '@src/utils/utils';
import { mapTargetToolToBackendKey } from '@src/utils/targetToolsUtil';
import type { SelectProps } from '@radix-ui/react-select';

type Props = SelectProps & {
  value?: Tools;
  onValueChange?: (value: Tools) => void;
  className?: string;
};

const TargetingToolSelect = (props: Props) => {
  return (
    <Select {...props}>
      <SelectTrigger
        className={cn(
          'min-w-[130px] child:flex child:items-center child:gap-x-2',
          props.className
        )}
      >
        <SelectValue placeholder="Target" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="notSpecified" className="text-opacity-40">
            Target Tool
          </SelectItem>
          {Object.keys(mapTargetToolToBackendKey).map((key) => {
            const Icon = getIcon(key);
            if (key === 'notSpecified') return null;
            return (
              <SelectItem
                key={key}
                value={key}
                className="child:flex child:items-center child:gap-x-2"
              >
                <Icon />
                <span>{_.startCase(key)}</span>
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default TargetingToolSelect;
