import type { SidebarNavItem } from '@features/layout/dashboardInterface';
import { cn } from '@src/utils/utils';
import { Link, useLocation } from 'react-router-dom';
import { Icons, getIcon } from '../../components/icons';

function NavigationItem({
  disabled,
  href,
  title,
  icon,
  iconClassNames,
}: SidebarNavItem) {
  const { pathname } = useLocation();
  const Icon = getIcon(icon);
  const isActive = pathname === href;

  return (
    <Link to={disabled ? '/' : href}>
      <span
        className={cn(
          'group flex items-center rounded-md px-3 py-2 text-sm font-medium text-white',
          'hover:bg-accent hover:text-accent-foreground',
          isActive
            ? '!bg-white hover:bg-opacity-10 !text-blue-700 child:!stroke-blue-700 '
            : 'transparent',
          disabled && 'cursor-not-allowed opacity-80'
        )}
      >
        {Icon ? (
          <Icon
            className={cn(
              'mr-2 h-4 w-4 group-hover:stroke-accent-foreground',
              iconClassNames
            )}
          />
        ) : (
          <Icons.archive className="mr-2 h-4 w-4 group-hover:stroke-accent-foreground" />
        )}
        <span>{title}</span>
      </span>
    </Link>
  );
}

export default NavigationItem;
