import useTab from '@src/hooks/useTab';
import { useAppSelector } from '@src/store';
import { isBlankPrompt, type Tab } from '@src/store/features/tabsSlice';
import { useUpdatePromptMutation, useAddPromptMutation } from '../promptApi';
import { showToast } from '@src/plugins/toast';

const useModifyPrompt = () => {
  const isBlank = useAppSelector(isBlankPrompt);
  const [updatePrompt, { isLoading }] = useUpdatePromptMutation();
  const [addPrompt, { isLoading: isSavingNewPrompt }] = useAddPromptMutation();
  const { update } = useTab();

  const modify = async (data: Tab) => {
    const { id, name, phrase } = data;

    if (!name && !phrase) return;

    const _prompt = {
      id,
      phrase,
      name: name || phrase?.slice(0, 20),
    };

    update(data);

    try {
      if (isBlank) {
        await addPrompt(_prompt).unwrap();
      } else {
        await updatePrompt(_prompt).unwrap();
      }
      update(data);
    } catch (error) {
      showToast('warning', "Something wen't wrong.");
    }
  };

  return { isLoading: isLoading || isSavingNewPrompt, modify };
};

export default useModifyPrompt;
