import IconWrapper from '@features/projects/components/ProjectIconWrapper';
import { useAddProjectMutation } from '@features/projects/projectsApi';
import { Button } from '@src/components/ui/Button';
import {
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from '@src/components/ui/Dialog';
import { Input } from '@src/components/ui/Input';
import { useState } from 'react';

type NewProjectDialogProps = {
  onClose: () => void;
};

const icons = [
  'book',
  'building',
  'camcorder',
  'camera',
  'cart',
  'comments',
  'food',
  'music',
  'box',
];

function NewProject({ onClose }: NewProjectDialogProps) {
  const [name, setName] = useState('');
  const [icon, setIcon] = useState('book');

  const [addProject, { isLoading }] = useAddProjectMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleSubmit = async () => {
    await addProject({ name , icon});
    setTimeout(() => {
      onClose();
    }, 500);
  };
  return (
    <>
      <DialogHeader>
        <DialogTitle>New project</DialogTitle>
      </DialogHeader>
      <div className="flex flex-col gap-4">
      <div className="mt-3 flex gap-1">
            {icons.map((iconName) => (
              <IconWrapper
                key={iconName}
                isActive={icon === iconName}
                name={iconName}
                onclick={setIcon}
              />
            ))}
          </div>
        <Input
          value={name}
          onChange={handleChange}
          placeholder="Project name..."
        />
      </div>
      <DialogFooter className="flex items-center !justify-between">
        <Button
          onClick={onClose}
          type="button"
          size={'sm'}
          className="px-6"
        >
          Back
        </Button>
        <Button
          disabled={name.trim().length === 0}
          onClick={handleSubmit}
          loading={isLoading}
          type="submit"
          size={'sm'}
          className="px-6"
        >
          Create
        </Button>
      </DialogFooter>
    </>
  );
}

export default NewProject;
