import {
  useListProjectsQuery,
  useUpdateGroupPromptProjectsMutation,
} from '@features/projects/projectsApi';
import {
  useUpdatePromptProjectMutation,
  useRemoveProjectFromPromptMutation,
  useRemoveProjectFromAllPromptsMutation,
} from '@features/prompts/promptApi';
import { getIcon } from '@src/components/icons';
import { Button } from '@src/components/ui/Button';
import {
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from '@src/components/ui/Dialog';
import { Input } from '@src/components/ui/Input';
import { Label } from '@src/components/ui/Label';
import { RadioGroup, RadioGroupItem } from '@src/components/ui/RadioGroup';
import { Skeleton } from '@src/components/ui/Skeleton';
import { showToast } from '@src/plugins/toast';
import { resetSelectedList } from '@src/store/features/promptSlice';
import { getActiveTab } from '@src/store/features/tabsSlice';
import { noop } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type ChooseProjectDialogProps = {
  onViewChange: () => void;
  promptId?: string;
  onClose: () => void;
  promptGroupList?: string[];
};

function ChooseProject({
  onViewChange,
  promptId = '',
  onClose,
  promptGroupList,
}: ChooseProjectDialogProps) {
  const { data:projects, isLoading } = useListProjectsQuery();
  const dispatch = useDispatch();
  const Archive = getIcon('archive');

  const [updatePromptProject, { isLoading: assignProjectLoading }] =
    useUpdatePromptProjectMutation();
  const [updateAll] = useUpdateGroupPromptProjectsMutation();
  const [removeProject] = useRemoveProjectFromPromptMutation();
  const [removeProjectFromAllPrompts] =
    useRemoveProjectFromAllPromptsMutation();
  const prompt = useSelector(getActiveTab);

  const [selectedProjectId, setSelectedProjectId] = useState(
    prompt?.project?.id || ''
  );

  const handleAssignProject = async () => {
    if (!selectedProjectId) {
      handleRemoveProject();
      return;
    }
    const project = projects?.data.find((p) => p.id === selectedProjectId);

    try {
      await updatePromptProject({
        promptId,
        projectId: selectedProjectId,
      }).unwrap();
      showToast('success', `The Prompt Added Successfully to ${project?.name}`);

      onClose();
    } catch {
      showToast('error', "You can't move an empty prompt to a project");
    }
  };

  const handleRemoveProject = async () => {
    if (!promptId) return;

    try {
      await removeProject(promptId).unwrap();
      showToast('success', 'The Prompt Removed Successfully from The Project');
      onClose();
    } catch (error) {
      noop();
    }
  };

  const handleMoveAllToProject = async () => {
    if (!promptGroupList) return;

    if (!selectedProjectId) {
      handleDeleteAllProjects();
      return;
    }

    try {
      await updateAll({
        prompts: promptGroupList,
        projectId: selectedProjectId,
      }).unwrap();
      showToast('success', 'Prompts Successfully Added to The Project');
      dispatch(resetSelectedList());
      onClose();
    } catch (error) {
      noop();
    }
  };

  const handleDeleteAllProjects = async () => {
    if (!promptGroupList) return;

    try {
      await removeProjectFromAllPrompts(promptGroupList).unwrap();
      showToast('success', 'Project removed from all prompts');
      dispatch(resetSelectedList());
      onClose();
    } catch (error) {
      noop();
    }
  };

  const skeleton = (
    <div className="flex flex-col gap-1 px-2">
      {Array.from({ length: 5 }).map((_, i) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Skeleton key={i} className="h-4 w-1/4 bg-slate-200" />
      ))}
    </div>
  );


  const content = isLoading || !projects ? (
    skeleton
  ) : (
    <RadioGroup
      onValueChange={setSelectedProjectId}
      defaultValue={selectedProjectId}
    >
      <div className="flex items-center space-x-2">
        <RadioGroupItem value={''} id={'none'} />
        <Archive className="w-4 h-4" />
        <Label htmlFor={'none'}>None</Label>
      </div>
      {projects.data.map((project) => {
        const Icon = getIcon(project.icon);
        console.log('ChooseProject' ,Icon);

        return (
          <div key={project.id} className="flex items-center space-x-2">
            <RadioGroupItem value={project.id} id={project.id} />
            <Icon className="w-4 h-4" />
            <Label htmlFor={project.id}>{project.name}</Label>
          </div>
        );
      })}
    </RadioGroup>)

  return (
    <>
      <DialogHeader>
        <DialogTitle>Move to project</DialogTitle>
      </DialogHeader>
      <div className="flex flex-col gap-5">
        <Input placeholder="Search projects..." className="shadow-md" />
        <div className=" overflow-auto">
          {content}
        </div>
      </div>
      <DialogFooter className="!justify-between ">
        <Button
          onClick={onViewChange}
          variant="ghost"
          size="sm"
          className="text-gray-500"
        >
          New project...
        </Button>
        <Button
          size="sm"
          onClick={promptId ? handleAssignProject : handleMoveAllToProject}
          type="submit"
          loading={assignProjectLoading}
          className="px-8"
        >
          Move
        </Button>
      </DialogFooter>
    </>
  );
}

export default ChooseProject;
