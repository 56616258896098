import type { SelectProps } from '@radix-ui/react-select';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/Select';
import type { Sort } from '@features/layout/dashboardInterface';

type Props = SelectProps & {
  value?: Sort;
  onValueChange?: (value: Sort) => void;
};

const FilterSelect = (props: Props) => {
  return (
    <Select {...props}>
      <SelectTrigger className="w-[90px]">
        <SelectValue placeholder="Sort by" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Sort by</SelectLabel>
          <SelectItem value="Name">Name</SelectItem>
          <SelectItem value="CreateDate">Date</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default FilterSelect;
