import { api } from '@src/store/api';
import type { ApiResponse } from '@src/types/publicTypes';

interface Project {
  id: string;
  name: string;
  icon: string;
  prompts?: Array<string>;
}

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listProjects: builder.query<ApiResponse<Project[]>, void>({
      query: () => '/project',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Project' as const,
                id,
              })),
              { type: 'Project', id: 'LIST' },
            ]
          : [{ type: 'Project', id: 'LIST' }],
    }),
    updateProject: builder.mutation<
      void,
      Partial<Project> & Pick<Project, 'id'>
    >({
      query: ({ ...put }) => ({
        url: '/project',
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
    }),
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    getProjectById: builder.query<any, any>({
      query: (id) => ({
        url: `/project/${id}`,
        method: 'Get',
      }),
      providesTags: ['Project'],
    }),
    deleteProject: builder.mutation<{ success: boolean; id: number }, string>({
      query(id) {
        return {
          url: `/project/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (project) => [{ type: 'Project', id: project?.id }],
    }),
    addProject: builder.mutation<Project, Partial<Project>>({
      query(body) {
        return {
          url: 'project',
          method: 'POST',
          body: { icon: 'Default', ...body },
        };
      },
      invalidatesTags: (project) => [{ type: 'Project', id: project?.id }],
    }),
    updateGroupPromptProjects: builder.mutation<
      void,
      { projectId: string; prompts: string[] }
    >({
      query({ projectId, prompts }) {
        return {
          url: `/project/${projectId}/prompt`,
          method: 'PATCH',
          body: prompts,
        };
      },
      invalidatesTags: ['Prompt'],
    }),
  }),
});

export const {
  useGetProjectByIdQuery,
  useListProjectsQuery,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useUpdateGroupPromptProjectsMutation,
} = projectApi;
