import { Input } from '@src/components/ui/Input';
import { useAppSelector } from '@src/store';
import { getActiveTab } from '@src/store/features/tabsSlice';
import { cn } from '@src/utils/utils';
import { useState, useEffect, useRef } from 'react';
import useModifyPrompt from '../hooks/useModifyPrompt';
import { Button } from '@src/components/ui/Button';
import useOutsideClick from '@src/hooks/useOutsideClick';

function TitleEditor() {
  const activeTab = useAppSelector(getActiveTab);
  const { modify, isLoading } = useModifyPrompt();
  const [title, setTitle] = useState(activeTab?.name || '');
  const [isEditing, setIsEditing] = useState(false);
  const hasNewTitle = title !== activeTab?.name;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTitle(activeTab?.name || '');
  }, [activeTab]);

  const updateTitle = () => {
    setIsEditing(false);
    modify({ ...activeTab, name: title });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleEdit  = () => {
    if(!isEditing) {
      setIsEditing(true
      );
    }
  };

  useOutsideClick(inputRef, ()=>{
    setIsEditing(false);
  });

  return (
    <div className=" grow">
      <div className="flex items-center gap-3 mb-1">
        <p className="text-slate-500 text-base ">Prompt title</p>
        {hasNewTitle && <Button size='sm' className='!h-6' onClick={updateTitle} disabled={isLoading}>Save</Button>}
      </div>
      <Input
        placeholder="Name your new prompt"
        value={title}
        name="name"
        onChange={handleChange}
        className={cn(
          isEditing
            ? 'border-2'
            : 'border-0 text-slate-900 text-2xl px-0 placeholder-red-300'
        )}
        onClick={handleEdit}
        autoComplete="off"
        readOnly={!isEditing}
        ref={inputRef}
      />
    </div>
  );
}
export default TitleEditor;
