import { Input } from '@src/components/ui/Input';
import { Textarea } from '@src/components/ui/Textarea';
import { showToast } from '@src/plugins/toast';
import { useDebounceFn } from 'ahooks';
import { useState } from 'react';

const Profile = () => {
  const [profile, setProfile] = useState({
    userName: 'jim jim',
    displayName: 'john doe',
    bio: 'I am a software engineer',
  });

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
    throttleSubmit.run();
  };

  const submitChanges = () => {
    showToast('success', 'Profile updated successfully!');
  };

  const throttleSubmit = useDebounceFn(submitChanges, {
    wait: 2_000,
  });

  return (
    <div className="w-full">
      <h2 className="font-semibold text-primary text-lg mb-5">Profile</h2>
      <form className="flex flex-col gap-4 w-1/2 max-w-[600px]">
        <div className="flex gap-6">
          <div className="flex flex-col gap-4 grow">
            <div>
              <label
                htmlFor="userName"
                className="mb-1.5 block text-sm text-primary font-medium"
              >
                Username
              </label>
              <Input
                placeholder="username..."
                id="userName"
                name="userName"
                value={profile.userName}
                onChange={changeHandler}
                autoComplete="off"
              />
            </div>

            <div>
              <label
                htmlFor="displayName"
                className="mb-1.5 block text-sm text-primary font-medium"
              >
                Display name
              </label>
              <Input
                placeholder="displayName..."
                id="displayName"
                name="displayName"
                value={profile.displayName}
                onChange={changeHandler}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="size-[124px] shadow-md rounded-md self-end p-3">
            <div className="rounded-full size-full bg-primary" />
          </div>
        </div>
        <div>
          <label
            htmlFor="bio"
            className="mb-1.5 block text-sm text-primary font-medium"
          >
            Bio
          </label>
          <Textarea
            placeholder="bio..."
            id="bio"
            name="bio"
            value={profile.bio}
            onChange={changeHandler}
            className="text-sm font-normal text-primary"
          />
        </div>
      </form>
    </div>
  );
};

export default Profile;
