function importAll(r: __WebpackModuleApi.RequireContext) {
  if (!r) return {};

  const images: Record<string, string> = {};

  r.keys().map(
    (fileName: string) =>
      // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
      (images[fileName.replace('./', '').replace('.svg', '')] = r(fileName))
  );

  return images;
}

export const socialIcons = importAll(
  require.context('./social', false, /\.(svg)$/)
);

export const logoIcons = importAll(
  require.context('./logo', false, /\.(svg)$/)
);
