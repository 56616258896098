// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-855f3b0a83/0/cache/css-loader-npm-6.11.0-d945f9f4c0-bb52434138.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-855f3b0a83/0/cache/css-loader-npm-6.11.0-d945f9f4c0-bb52434138.zip/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-855f3b0a83/0/cache/css-loader-npm-6.11.0-d945f9f4c0-bb52434138.zip/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/fa-solid-900.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/fa-solid-900.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */:host,:root{--fa-style-family-classic:"Font Awesome 6 Pro";--fa-font-solid:normal 900 1em/1 "Font Awesome 6 Pro"}@font-face{font-display:block;font-family:Font Awesome\\ 6 Pro;font-style:normal;font-weight:900;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype")}.fa-solid,.fas{font-weight:900}`, "",{"version":3,"sources":["webpack://./src/assets/styles/solid.css"],"names":[],"mappings":"AAAA;;;;EAAA,CAKC,YACC,8CAAA,CACA,qDACF,CACA,WAIE,kBAAA,CAHA,+BAAA,CACA,iBAAA,CACA,eAAA,CAEA,sHAEF,CAAA,eAEE,eAEF","sourcesContent":["/*!\r\n * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com\r\n * License - https://fontawesome.com/license (Commercial License)\r\n * Copyright 2023 Fonticons, Inc.\r\n */\r\n :root, :host {\r\n  --fa-style-family-classic: 'Font Awesome 6 Pro';\r\n  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }\r\n\r\n@font-face {\r\n  font-family: 'Font Awesome 6 Pro';\r\n  font-style: normal;\r\n  font-weight: 900;\r\n  font-display: block;\r\n  src: url(\"../fonts/fa-solid-900.woff2\") format(\"woff2\"), url(\"../fonts/fa-solid-900.ttf\") format(\"truetype\"); }\r\n\r\n.fas,\r\n.fa-solid {\r\n  font-weight: 900; }\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
