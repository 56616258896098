import { getCookie } from '@src/utils/cookieUtils';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const token = getCookie('token');
  if (!token) return <Navigate to="/login" />;

  return <Outlet />;
};

export default ProtectedRoute;
