import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const STATIC_TAB = ['/', '/recent', '/projects'];
const PARAMS_TAB = ['/projects/'];

export const isHomeTabActive = (pathname: string | null) => {
  return Boolean(
    pathname &&
      (STATIC_TAB.includes(pathname) ||
        PARAMS_TAB.some((item) => pathname.startsWith(item)))
  );
};

export function generateRandomHex(length = 24) {
  let hexString = '';
  for (let i = 0; i < length; i++) {
    hexString += Math.floor(Math.random() * 16).toString(16);
  }
  return hexString;
}

export const getKeyByValue = (source: object, value: string) => {
  const indexOfS = Object.values(source).indexOf(value as unknown);

  const key = Object.keys(source)[indexOfS];

  return key;
};
