import type { Tools } from '@features/layout/dashboardInterface';
import { useUpdateToolMutation } from '@features/prompts/promptApi';
import { showToast } from '@src/plugins/toast';
import { useAppSelector } from '@src/store';
import { getActiveTab } from '@src/store/features/tabsSlice';
import { mapTargetToolToBackendKey } from '@src/utils/targetToolsUtil';
import { useDebounceFn } from 'ahooks';
import TargetingToolSelect from './TargetingToolSelect';

function TargetToolSelection() {
  const promptData = useAppSelector(getActiveTab);

  const [updateTool] = useUpdateToolMutation();

  const submit = async (value: Tools) => {
    if (!promptData) return;
    try {
      await updateTool({
        id: promptData.id,
        targetTool: mapTargetToolToBackendKey[value],
      }).unwrap();

      showToast('success', `Target tool updated to ${value}`);
    } catch (error) {
      showToast('error', "You can't choose a target tool for an empty prompt");
    }
  };
  const { run } = useDebounceFn(submit, { wait: 500 });

  return (
    <div className="flex flex-col gap-2 w-[200px]">
      <span className="text-slate-600 text-sm font-medium">Target tool</span>
      <TargetingToolSelect
        value={promptData?.targetTool}
        onValueChange={run}
        className="w-[200px]"
      />
    </div>
  );
}
export default TargetToolSelection;
