import ActionItem from '@src/components/ui/ActionItem';
import useDisclosure from '@src/hooks/useDisclosure';
import { useAppSelector } from '@src/store';
import { getActiveTab } from '@src/store/features/tabsSlice';
import MoveToProject from './MoveProjects';
import { Icons, getIcon } from '@src/components/icons';

const PromptProject = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const promptData = useAppSelector(getActiveTab);
  const projectIconKey = promptData?.project?.icon;
  const projectIcon = projectIconKey ? getIcon(projectIconKey) : Icons.forward;

  return (
    <div className="flex flex-col gap-2 w-[200px]">
      <span className="text-slate-600 text-sm font-medium ">Project</span>
      <ActionItem
        label={promptData?.project?.name || 'Move to project'}
        icon={projectIcon}
        variant="default"
        className="border-2 border-slate-200 rounded-md h-10"
        onClick={onOpen}
      />

      <MoveToProject onClose={onClose} id={promptData?.id} isOpen={isOpen} />
    </div>
  );
};

export default PromptProject;
