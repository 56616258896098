import TargetToolSelection from './TargetToolSelection';
import PromptProject from './moveToProject/PromptProject';
import PromptSettings from './PromptSettings';

function PromptAssets({ id, isBlank }: { id: string; isBlank: boolean }) {
  return (
    <>
      <TargetToolSelection />
      <PromptProject />
      {!isBlank && <PromptSettings id={id} />}
    </>
  );
}
export default PromptAssets;
