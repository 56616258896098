import Title from '../../components/ui/Title';
import ProjectNav from './ProjectNav';
import ToolsNav from './ToolsNav';
import { dashboardConfig } from '@src/utils/navbarConstants';
import { getIcon } from '@src/components/icons';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@src/utils/utils';

export function DashboardNav() {
  const { pathname } = useLocation();

  return (
    <nav className="grid items-start gap-2 ps-3 pe-5 pt-6 sticky top-14">
      {/* prompts section */}
      <Title className="ps-4" title="Prompts" />
      {dashboardConfig.sidebarNav.map(({ href, title, icon }, index) => {
        const Icon = getIcon(icon);
        const isActive = pathname === href;

        return (
          <Link to={href} key={href}>
            <span
              className={cn(
                'group flex items-center rounded-md px-3 py-2 text-sm font-medium text-white',
                'hover:bg-accent hover:text-accent-foreground',
                isActive
                  ? '!bg-white hover:bg-opacity-10 !text-blue-700 child:!stroke-blue-700 '
                  : 'transparent'
              )}
            >
              <Icon className="mr-2 h-4 w-4 group-hover:stroke-accent-foreground" />
              <span>{title}</span>
            </span>
          </Link>
        );
      })}

      {/* projects section */}
      <ProjectNav />

      {/* targeting tools section */}
      <ToolsNav />
    </nav>
  );
}
