import { random } from 'lodash';
import PromptCardSkeleton from './prompt-card-skeleton';

export const PromptsSkeleton = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {new Array(5).fill(0).map((_, i) => (
        <PromptCardSkeleton key={random()} />
      ))}
    </div>
  );
};
