import { configureStore } from '@reduxjs/toolkit';

import promptSlice from './features/promptSlice';
import { type TypedUseSelectorHook, useSelector } from 'react-redux';
import { api } from './api';
import tabsSlice, { syncTabsToStorage } from './features/tabsSlice';
import { appSlice } from './features/appSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [appSlice.name]: appSlice.reducer,
    [promptSlice.name]: promptSlice.reducer,
    [tabsSlice.name]: tabsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([api.middleware, syncTabsToStorage]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector<RootState>;
