import type { Prompt, PromptImage } from '@features/prompts/promptInterface';
import {
  createSlice,
  type PayloadAction,
  createSelector,
  type AnyAction,
} from '@reduxjs/toolkit';
import type { RootState } from '@src/store';

const getTabsFromLocalStorage = () => {
  const tabs = localStorage.getItem('tabs');
  return tabs ? JSON.parse(tabs) : [];
};

type Tab = Prompt & {
  selected: boolean;
};

type InitialState = {
  tabs: Array<Tab>;
  enableTabs: boolean;
};

const initialState: InitialState = {
  tabs: getTabsFromLocalStorage(),
  enableTabs: true,
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    updateTabs(state, action: PayloadAction<Tab[]>) {
      state.tabs = action.payload;
    },
    updateEnableTabs(state, action: PayloadAction<boolean>) {
      state.enableTabs = action.payload;
    },
  },
});

const getTabs = (state: RootState) => state.tabs.tabs;

const getActiveTab = (state: RootState) =>
  state.tabs.tabs.find(({ selected }) => selected);

const isBlankPrompt = createSelector(
  getActiveTab,
  (activeTab) => !activeTab || (!activeTab.name && !activeTab.phrase)
);

const getPromptPhrase = createSelector(getActiveTab, (activeTab) => {
  if (!activeTab) return '';

  const images = activeTab?.images
    ?.filter((image) => image.origin === 'Input')
    ?.map((image: PromptImage) => image.url)
    .join(', ');

  return images ? `${images}, ${activeTab?.phrase}` : activeTab?.phrase;
});

const getTabsState = (state: RootState) => state.tabs.enableTabs;

export const syncTabsToStorage =
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  () => (next: any) => (action: AnyAction) => {
    if (action.type === 'tabs/updateTabs') {
      localStorage.setItem('tabs', JSON.stringify(action.payload));
    }
    return next(action);
  };

export { getTabs, getActiveTab, isBlankPrompt, getPromptPhrase, getTabsState };
export type { Tab };

export const { updateTabs, updateEnableTabs } = tabsSlice.actions;

export default tabsSlice;
