import type { Tools } from '@features/layout/dashboardInterface';
import { api } from '@src/store/api';
import type { ApiResponse } from '@src/types/publicTypes';
import { noop } from 'lodash';

export type AssetInfo = {
  name: string;
  promptId: string;
  description?: string;
  id: string;
  extension: string;
  systemName: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  size?: any;
  url: string;
  createdDatetime: string;
  thumbnail?: string;
  updatedDateTime: string;
  origin: 'Input' | 'Output';
  targetTool?: Tools;
};

export const assetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImageAssets: builder.query<AssetInfo[], string>({
      query: (promptId) => `/asset/prompt/${promptId}/image`,
      transformResponse: (response: ApiResponse<AssetInfo[]>) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Assets' as const,
                id,
              })),
              { type: 'Assets', id: 'LIST' },
            ]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    getVideoAssets: builder.query<ApiResponse<AssetInfo[]>, string>({
      query: (promptId: string) => `/asset/prompt/${promptId}/video`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Assets' as const,
                id,
              })),
              { type: 'Assets', id: 'LIST' },
            ]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    deleteAsset: builder.mutation({
      query: (assetId: string) => ({
        url: `/asset/${assetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Assets', id },
        { type: 'Prompt' },
      ],
    }),
    setDescriptionAsset: builder.mutation({
      query: ({
        assetId,
        description,
      }: {
        assetId: string;
        description: string;
      }) => ({
        url: `/asset/${assetId}`,
        method: 'PATCH',
        body: { description },
      }),
    }),
    renameAsset: builder.mutation({
      query: ({ assetId, name }: { assetId: string; name: string }) => ({
        url: '/asset',
        method: 'PATCH',
        body: { name, id: assetId },
      }),
      async onQueryStarted(
        { assetId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          assetApi.util.updateQueryData('getImageAssets', assetId, (draft) => {
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { assetId }) => [
        { type: 'Assets', id: assetId },
      ],
    }),
    uploadNewAsset: builder.mutation({
      query: ({
        promptId,
        file,
        origin,
      }: {
        promptId: string;
        file: File;
        origin: 'Output' | 'Input';
      }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `/asset/prompt/${origin}/${promptId}`,
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          noop();
        } finally {
          setTimeout(() => {
            dispatch(
              assetApi.util.invalidateTags(['Assets', { type: 'Prompt' }])
            );
          }, 2000);
        }
      },
    }),
  }),
});

export const {
  useDeleteAssetMutation,
  useGetImageAssetsQuery,
  useGetVideoAssetsQuery,
  useSetDescriptionAssetMutation,
  useRenameAssetMutation,
  useUploadNewAssetMutation,
} = assetApi;
