import { useAppSelector } from '@src/store';
import {
  getBulkMode,
  getSelectedPrompts,
  removePromptFromSelectedList,
  addPromptToSelectedList,
  updateSelectedList,
} from '@src/store/features/promptSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useSelectedPrompt = (id: string) => {
  const isBulkMode = useAppSelector(getBulkMode);
  const prompts = useAppSelector(getSelectedPrompts);
  const isSelected = prompts.includes(id);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  const updateBulk = () => {
    if (isSelected) {
      dispatch(removePromptFromSelectedList(id));
      return;
    }
    dispatch(addPromptToSelectedList(id));
  };

  const updatePromptState = () => {
    if (isBulkMode && isSelected) return;

    if (!isSelected) {
      dispatch(updateSelectedList(id));
      return;
    }

    if (isSelected) {
      dispatch(removePromptFromSelectedList(id));
      return;
    }
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleSelectPrompt = (event: any) => {
    // Perform action for Command + Click
    if ((event.ctrlKey || event.metaKey) && isHomePage) {
      updateBulk();
    } else {
      updatePromptState();
    }
  };

  return {
    handleSelectPrompt,
  };
};

export default useSelectedPrompt;
