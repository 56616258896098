import type { Tools } from '@features/layout/dashboardInterface';
import ImagePlaceholder from '@features/prompts/components/ImagePlaceholder';
import MoveToProject from '@features/prompts/components/moveToProject/MoveProjects';
import type { Prompt } from '@features/prompts/promptInterface';
import useDisclosure from '@src/hooks/useDisclosure';
import useDragAndDrop from '@src/hooks/useDragAndDrop';
import useOutsideClick from '@src/hooks/useOutsideClick';
import useSelectedPrompt from '@src/hooks/useSelectedPrompt';
import useTab from '@src/hooks/useTab';
import { resetSelectedList } from '@src/store/features/promptSlice';
import { cn } from '@src/utils/utils';
import { memo, useRef, type DragEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardDescription } from '../../../components/ui/Card';
import ToolsSelector from './ToolsSelector';
import { PromptCardActions } from './PromptCardActions';
import { randomColor } from './getRandomColor';

type PromptCardProps = {
  prompt: Prompt;
  isSelected?: boolean;
};

// eslint-disable-next-line react/display-name
const PromptCard = memo(({ prompt, isSelected }: PromptCardProps) => {
  const { id, name, cover, phrase, targetTool } = prompt;
  const colorClass = randomColor(phrase || name);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { open } = useTab();
  const { handleSelectPrompt } = useSelectedPrompt(id);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { onDragStart, onDragEnd, isDragging } = useDragAndDrop(
    id,
    cover ?? '',
    colorClass
  );

  const clickHandler = (element: EventTarget) => {
    const closestPromptCard = !!(element as HTMLElement).closest(
      '.prompt-area'
    );

    if (closestPromptCard) return;
    dispatch(resetSelectedList());
  };

  useOutsideClick(ref, clickHandler);

  return (
    <>
      <Card
        draggable
        onDragStart={onDragStart as unknown as DragEventHandler<HTMLDivElement>}
        onDragEnd={onDragEnd}
        onDoubleClick={() => {
          open({ ...prompt, selected: true });
        }}
        onClick={handleSelectPrompt}
        id={`prompt-card-${id}`}
        className={cn(
          'prompt-area group w-[216px] h-[320px] overflow-hidden rounded-md flex flex-col cursor-pointer shadow-xs transition-all duration-200 ease-in-out',
          isDragging && 'opacity-20 ',
          isSelected && 'ring-2 ring-offset-0 ring-slate-500'
        )}
        ref={ref}
      >
        <CardHeader className="p-0 h-[196px]">
          {cover ? (
            <img
              className="w-full h-full object-cover object-center "
              alt={`prompt cover for ${name}`}
              src={cover}
              draggable={false}
            />
          ) : (
            <ImagePlaceholder text={phrase || name} />
          )}
        </CardHeader>
        <div className="p-3 grow relative">
          <CardDescription className="mb-2 text-primary font-semibold truncate">
            {name}
          </CardDescription>
          <CardDescription className="text-slate-500 !line-clamp-2 mb-2	">
            {phrase}
          </CardDescription>
          <div className="absolute bottom-2 left-3 flex gap-3 items-center">
            <ToolsSelector tool={targetTool as Tools} />
            <PromptCardActions
              prompt={prompt}
              onOpen={onOpen}
              align="start"
              className={cn(
                'opacity-0 group-hover:opacity-100',
                isSelected && 'opacity-100'
              )}
            />
          </div>
        </div>
      </Card>
      <MoveToProject onClose={onClose} id={id} isOpen={isOpen} />
    </>
  );
});

export default PromptCard;
