import { dashboardConfig } from '@src/utils/navbarConstants';
import { cn } from '@src/utils/utils';
import { Link } from 'react-router-dom';
import Logo from '@assets/svg/logo/logo.png';

interface MobileNavProps {
  onItemClick?: () => void;
  children?: React.ReactNode;
}

export function MobileNav({ children, onItemClick }: MobileNavProps) {
  return (
    <div
      className={cn(
        'fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 md:hidden'
      )}
    >
      <div className="relative z-20 grid gap-6 rounded-md bg-popover p-4 text-popover-foreground shadow-md">
        <Link to="/" className="flex items-center space-x-2">
          <img src={Logo} alt="Prompt Studio Logo" className="w-[110px]" />
        </Link>
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          {dashboardConfig.sidebarNav.map((item, index) => (
            <Link
              key={item.href}
              to={item.disabled ? '#' : item.href}
              onClick={onItemClick}
              className={cn(
                'flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline',
                item.disabled && 'cursor-not-allowed opacity-60'
              )}
            >
              {item.title}
            </Link>
          ))}
        </nav>
        {children}
      </div>
    </div>
  );
}
