import { toast } from 'react-toastify';

import type { ToastOptions } from 'react-toastify';
import type { ToastProps } from 'react-toastify/dist/types';

export const configToast = (type: ToastProps['type']): ToastOptions => ({
  type: type,
  position: 'bottom-right',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  theme: 'colored',
  className: 'text-sm',
});

export const showToast = (
  type: ToastProps['type'],
  title: JSX.Element | string
): void => {
  toast(title, configToast(type));
};
