import ConfirmDelete from '@src/components/ui/ConfirmDelete';
import useDisclosure from '@src/hooks/useDisclosure';
import useTab from '@src/hooks/useTab';
import { useAppSelector } from '@src/store';
import { getTabs } from '@src/store/features/tabsSlice';
import { cn } from '@src/utils/utils';
import { noop } from 'lodash';
import {
  useDeletePromptMutation,
  useDuplicatePromptMutation,
} from '../promptApi';
import { showToast } from '@src/plugins/toast';
import { Icons, getIcon } from '@src/components/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@src/components/ui/DropdownMenu';
import { useDispatch } from 'react-redux';

const PromptSettings = ({ id }: { id: string }) => {
  const Gear = getIcon('dotsHorizontal');
  const [remove] = useDeletePromptMutation();
  const [duplicate] = useDuplicatePromptMutation();
  const { close } = useTab();
  const tabs = useAppSelector(getTabs);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const menuItems = [
    {
      label: 'Duplicate',
      className:
        ' focus:text-accent-foreground flex items-center justify-between',
      icon: <Icons.copy className="mr-2 h-4 w-4" />,
      onselect: duplicatePrompt,
      shortcut: '⌘D',
    },
    {
      label: 'Delete',
      className:
        'flex items-center justify-between text-red-600 border-t border-slate-100 rounded-none',
      icon: <Icons.x className="mr-2 h-4 w-4" />,
      onselect: onOpen,
    },
  ];

  async function deletePrompt() {
    try {
      await remove(id).unwrap();
      showToast('success', 'Prompt deleted successfully');
      close(id);
    } catch (error) {
      noop();
    }
  }

  async function duplicatePrompt() {
    try {
      await duplicate(id).unwrap();
      showToast('success', 'Prompt duplicated successfully');
    } catch (error) {
      noop();
    }
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="self-end ">
          <p className="h-10 w-10 border-2 rounded-md flex items-center justify-center text-slate-500">
            <Gear className="h-5 w-5 text-slate-600" />
          </p>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[224px] p-0">
          {menuItems.map((item) => (
            <DropdownMenuItem
              key={item.label}
              onSelect={(e) => {
                e.stopPropagation();
                item.onselect();
              }}
              className={cn(
                item.className,
                'px-4 py-3 text-sm focus:bg-accent cursor-pointer'
              )}
              shortcut={item.shortcut}
            >
              <div className="flex items-center">
                {item.icon}
                {item.label}
              </div>
              <div className="text-slate-500">{item.shortcut}</div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmDelete
        title="Delete prompt"
        description="Are you sure you want to delete this prompt?"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={deletePrompt}
      />
    </>
  );
};

export default PromptSettings;
