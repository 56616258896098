import { MainNav } from '@features/layout/MainNav';
import { cn } from '@src/utils/utils';
import { Link, Outlet, useLocation } from 'react-router-dom';

const items = [
  {
    title: 'Profile',
    path: '/settings/profile',
  },
  {
    title: 'Account',
    path: '/settings/account',
  },
];

const SettingsLayout = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <nav className="sticky top-0 z-40 bg-primary text-background">
        <div className="pe-6 flex h-14 items-center justify-between">
          <MainNav hidePlusIcon />
        </div>
      </nav>
      <main className="p-8 ">
        <div className="pb-6 border-b border-slate-200">
          <h2 className="text-3xl font-semibold mb-2">Settings</h2>
          <p className="text-slate-500 text-sm">
            Manage your profile and settings
          </p>
        </div>
        <div className="flex pt-8">
          <aside className="w-[200px]">
            <ul>
              {items.map((item, index) => {
                const isActive = pathname === item.path;

                return (
                  <li
                    key={item.path}
                    className={cn(
                      'text-slate-800 text-sm hover:font-semibold transition-all mb-3',
                      { 'font-semibold': isActive }
                    )}
                  >
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          </aside>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default SettingsLayout;
