import type { DashboardConfig } from '@features/layout/dashboardInterface';

export const dashboardConfig: DashboardConfig = {
  sidebarNav: [
    {
      title: 'All prompts',
      href: '/',
      icon: 'layoutGrid',
    },
    {
      title: 'Recent',
      href: '/recent',
      icon: 'history',
    },
  ],
};
