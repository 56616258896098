import { api } from '@src/store/api';
import type { ApiResponse } from '@src/types/publicTypes';
import type { Token, Login } from './accountInterface';

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ApiResponse<Token>, Login>({
      query(body: Login) {
        return {
          url: 'account/login',
          method: 'POST',
          body: { ...body },
        };
      },
    }),
    getGoogleLink: builder.mutation<ApiResponse<string>, void>({
      query() {
        return {
          url: 'account/login/google',
          method: 'GET',
        };
      },
    }),
    googleLogin: builder.mutation<ApiResponse<Token>, string>({
      query(code: string) {
        return {
          url: `account/login/google/${code}`,
          method: 'GET',
        };
      },
    }),
  }),
});
//throw new Error(data.errorMessage);
export const {
  useLoginMutation,
  useGoogleLoginMutation,
  useGetGoogleLinkMutation,
} = accountApi;
