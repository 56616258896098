import { api } from '@src/store/api';
import type { ApiResponse } from '@src/types/publicTypes';
import type { Token } from './accountInterface';

const isDev = process.env.NODE_ENV === 'development';

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGoogleLink: builder.mutation<ApiResponse<string>, void>({
      query() {
        return {
          url: 'account/login/google',
          method: 'GET'
        };
      },
    }),
    googleLogin: builder.mutation<ApiResponse<Token>, string>({
      query(code: string) {
        return {
          url: `account/login/google/${code}`,
          method: 'GET'
        };
      },
    }),
  }),
});
//throw new Error(data.errorMessage);
export const { useGoogleLoginMutation, useGetGoogleLinkMutation } = accountApi;
