import type { Tools } from '@features/layout/dashboardInterface';
import useTab from '@src/hooks/useTab';
import { cn } from '@src/utils/utils';
import { memo } from 'react';
import { Button } from '../ui/Button';
import ToolsSelector from '../../features/prompts/components/ToolsSelector';
import { Icons } from '../icons';
import { useAppSelector } from '@src/store';
import { getTabsState } from '@src/store/features/tabsSlice';
import Spinner from '../ui/Spinner';

type TabItemProps = {
  onClick: () => void;
  active?: boolean;
  icon?: keyof typeof Icons;
  title?: string;
  id?: string;
  targetTool?: Tools;
};

function TabItem({
  active,
  icon = 'penCircle',
  title,
  onClick,
  id,
  targetTool,
}: TabItemProps) {
  const { close } = useTab();
  const Icon = Icons[icon];
  const isPromptTab = !['home', 'add'].includes(icon);
  const placeholder = isPromptTab ? 'untitled prompt' : '';
  const tabTitle = title || placeholder || '';
  const tabsState = useAppSelector(getTabsState);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    close(id);
  };

  return (
    <div
      onClick={onClick}
      className={cn(
        'relative w-11 group h-12 py-1 px-2 cursor-pointer rounded-t-md text-sm font-medium flex items-center',
        isPromptTab &&
          'after:content[ ] after:absolute after:right-0 after:h-6 after:w-[1px] after:bg-slate-700',
        active && 'text-primary bg-white after:content-none',
        tabTitle && 'w-[212px]'
      )}
    >
      <div
        className={cn(
          'flex justify-between items-center py-2 px-1 grow h-9 w-44',
          !active && isPromptTab && 'hover:bg-black rounded-md'
        )}
      >
        <div className="flex gap-2 items-center max-w-[85%] grow ">
          {!!targetTool && targetTool !== 'notSpecified' ? (
            <ToolsSelector tool={targetTool} />
          ) : (
            <Icon className={cn('fa-lg', !active && 'text-white bg-sky-700')} />
          )}
          <span className="text-ellipsis overflow-hidden whitespace-nowrap w-[80%]">
            {tabTitle}
          </span>
          {!tabsState && active && <Spinner/>}
        </div>
        <div
          className={cn(
            'gap-1 items-center transition-all duration-800 hidden',
            isPromptTab && 'group-hover:flex',
            active && isPromptTab && 'flex'
          )}
          onClick={handleClose}
        >
          <Button
            variant={active ? 'ghost' : 'natural'}
            size="sm"
            className="rounded-full w-4 h-6"
          >
            <Icons.xmark />
          </Button>
        </div>
      </div>
    </div>
  );
}
export default memo(TabItem);
