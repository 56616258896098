import { useAppSelector } from '@src/store';
import {
  type Tab,
  updateTabs,
  getTabs,
  getActiveTab,
} from '@src/store/features/tabsSlice';
import { generateRandomHex } from '@src/utils/utils';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const defaultTab = (): Tab => ({
  id: generateRandomHex(),
  name: '',
  cover: '',
  images: [],
  phrase: '',
  project: null,
  targetTool: 'notSpecified',
  updatedDateTime: '',
  videos: [],
  selected: true,
  isSelected: false,
});

const useTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = useAppSelector(getTabs);
  const activeTab = useAppSelector(getActiveTab);

  const open = (data?: Tab) => {
    const tab = data || defaultTab();
    const _tabs = _.cloneDeep(tabs);

    const index = _tabs.findIndex(({ id }) => id === tab.id);

    // Prevent opening the same tab twice
    if (index !== -1) {
      navigate(`/prompt/${tab.id}`);
      return;
    }

    for (const tab of _tabs) {
      tab.selected = false;
    }

    _tabs.push({ ...tab, selected: true });
    dispatch(updateTabs(_tabs));
    navigate(`/prompt/${tab.id}`);
  };

  const close = (id: string) => {
    if (tabs.length === 1) {
      dispatch(updateTabs([]));
      navigate('/');
      return;
    }
    const isClosingActiveTab = activeTab?.id === id;
    const _tabs = _.cloneDeep(tabs);
    const index = _tabs.findIndex(({ id: tabId }) => tabId === id);
    const nextTabIndex = !isClosingActiveTab
      ? index
      : index - 1 >= 0
        ? index - 1
        : index + 1;
    _tabs[nextTabIndex].selected = true;
    const targetId = _tabs[nextTabIndex].id;
    _tabs.splice(index, 1);
    dispatch(updateTabs(_tabs));
    if (isClosingActiveTab) navigate(`/prompt/${targetId}`);
  };

  const alter = (id: string) => {
    const _tabs = _.cloneDeep(tabs);

    for (const tab of _tabs) {
      tab.selected = false;
    }

    const index = _tabs.findIndex(({ id: tabId }) => tabId === id);
    _tabs[index].selected = true;

    dispatch(updateTabs(_tabs));
    navigate(`/prompt/${id}`);
  };

  const update = (tab: Tab) => {
    const _tabs = _.cloneDeep(tabs);
    const index = _tabs.findIndex(({ id }) => id === tab.id);
    _tabs[index] = tab;
    dispatch(updateTabs(_tabs));
  };

  return { open, close, alter, update };
};

export default useTab;
