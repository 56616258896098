import { useAddProjectMutation } from '@features/projects/projectsApi';
import { Icons } from '@src/components/icons';
import { Button } from '@src/components/ui/Button';
import {
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from '@src/components/ui/Dialog';
import { Input } from '@src/components/ui/Input';
import { useState } from 'react';

type NewProjectDialogProps = {
  onClose: () => void;
};

function NewProject({ onClose }: NewProjectDialogProps) {
  const [name, setName] = useState('');

  const [addProject, { isLoading }] = useAddProjectMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleSubmit = async () => {
    await addProject({ name });
    setTimeout(() => {
      onClose();
    }, 500);
  };
  return (
    <>
      <DialogHeader>
        <DialogTitle>New project</DialogTitle>
      </DialogHeader>
      <div className="flex flex-col gap-2">
        <Icons.archive className="h-4 w-4 cursor-pointer mb-2" />
        <Input
          value={name}
          onChange={handleChange}
          placeholder="Project name..."
        />
      </div>
      <DialogFooter className="">
        <Button
          disabled={name.trim().length === 0}
          onClick={handleSubmit}
          loading={isLoading}
          type="submit"
          size={'sm'}
          className="px-6"
        >
          Create
        </Button>
      </DialogFooter>
    </>
  );
}

export default NewProject;
