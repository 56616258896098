import { useDeleteAssetMutation } from '@features/prompts/assetApi';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@src/components/ui/DropdownMenu';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import useDisclosure from '@src/hooks/useDisclosure';
import { showToast } from '@src/plugins/toast';
import { setImagePreview } from '@src/store/features/appSlice';
import { downloadImage } from '@src/utils/download';
import { cn } from '@src/utils/utils';
import { noop } from 'lodash';
import { useDispatch } from 'react-redux';
import ConfirmDelete from '../../../components/ui/ConfirmDelete';
import { Icons } from '../../../components/icons';

type ThumbnailImageProps = {
  src: string;
  className?: string;
  alt: string;
  thumbnail?: string;
  name?: string;
  id: string;
  size?: 'small' | 'medium';
};

function ThumbnailImage({
  src,
  alt,
  thumbnail,
  name,
  className,
  id,
  size = 'small',
}: ThumbnailImageProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAsset] = useDeleteAssetMutation();
  const dispatch = useDispatch();

  const handleDeleteAsses = async () => {
    try {
      await deleteAsset(id).unwrap();
      showToast('success', 'Image deleted successfully');
    } catch (error) {
      noop();
    }
  };

  const menuItems = [
    {
      label: 'View',
      className:
        ' focus:text-accent-foreground flex items-center justify-between',
      icon: <Icons.eye className="w-4 h-4 mr-2" />,
      onselect: () =>
        dispatch(
          setImagePreview({
            name: name ?? alt,
            link: src,
            id,
            thumbnail,
          })
        ),
    },
    {
      label: 'Copy link',
      className:
        ' focus:text-accent-foreground flex items-center justify-between',
      icon: <Icons.link className="w-4 h-4 mr-2" />,
      onselect: () => {
        navigator.clipboard.writeText(src).then(() => {
          showToast('success', 'Prompt text copied to clipboard');
        });
      },
    },
    {
      label: 'Download',
      className:
        ' focus:text-accent-foreground flex items-center justify-between',
      icon: <Icons.download className="w-4 h-4 mr-2" />,
      onselect: () => downloadImage(src, name || alt),
    },
    {
      label: 'Delete',
      className:
        'flex items-center justify-between text-red-600 border-t border-slate-100 rounded-none',
      icon: <Icons.x className="mr-2 h-4 w-4" />,
      onselect: onOpen,
    },
  ];

  return (
    <>
      <div
        className={cn(
          'overflow-hidden',
          size === 'small' ? 'w-[100px]' : 'w-[156px]'
        )}
        id={`input-thumbnail-${name}`}
      >
        <div
          className={cn(
            'w-full relative group',
            size === 'small' ? 'h-[100px]' : 'h-[156px]'
          )}
        >
          <img src={thumbnail ?? src} alt={alt} className={className} />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <DotsHorizontalIcon
                className={cn(
                  'cursor-pointer rounded-full h-6 w-6 bg-gray-200 p-1 absolute bottom-1 right-1 text-slate-600',
                  'invisible group-hover:visible'
                )}
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="p-0">
              {menuItems.map((item) => (
                <DropdownMenuItem
                  key={item.label}
                  onSelect={(e) => {
                    e.stopPropagation();
                    item.onselect();
                  }}
                  className={cn(item.className, 'p-2 text-sm hover:bg-accent')}
                >
                  <div className="flex items-center">
                    {item.icon}
                    {item.label}
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <p className="text-sm text-slate-600 truncate mt-2">{name}</p>
      </div>
      <ConfirmDelete
        title="Delete image"
        description="Are you sure you want to delete this image?"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDeleteAsses}
      />
    </>
  );
}
export default ThumbnailImage;
