import {
  createCoveredGhost,
  createColoredGhost,
} from '@features/prompts/components/DargGhost';
import { useState } from 'react';

const useDragAndDrop = (id: string, cover: string, colorClass: string) => {
  const [isDragging, setIsDragging] = useState(false);

  const onDragStart = (e: DragEvent) => {
    if (e.dataTransfer === null) return;
    setIsDragging(true);
    e.dataTransfer.setData('prompt-card', id);
    const element = cover
      ? createCoveredGhost(cover)
      : createColoredGhost(colorClass);
    document.body.appendChild(element);
    e.dataTransfer.setDragImage(element, 10, 10);
  };

  const onDragEnd = () => {
    setIsDragging(false);
    const ghost = document.querySelector('.ghost');
    if (ghost) {
      ghost.remove();
    }
  };

  return { onDragStart, onDragEnd, isDragging };
};

export default useDragAndDrop;
