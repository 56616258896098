const downloadFile = (title: string, url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = title;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export async function downloadImage(imageSrc: string, title: string) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  downloadFile(title, imageURL);
}
