import type { Tools } from '@features/layout/dashboardInterface';
import type { Prompt } from '@features/prompts/promptInterface';
import useTab from '@src/hooks/useTab';
import { useAppSelector } from '@src/store';
import { getPrompts } from '@src/store/features/promptSlice';
import { getTabs } from '@src/store/features/tabsSlice';
import { eraseCookie } from '@src/utils/cookieUtils';
import { isHomeTabActive } from '@src/utils/utils';
import React from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import TabItem from '../../components/tabs/TabItem';
import { MobileNav } from './MobileNav';
import { Icons } from '../../components/icons';
import Logo from '@assets/svg/logo/logo.png';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@src/components/ui/DropdownMenu';

export function MainNav({
  children,
  hidePlusIcon = false,
}: {
  children?: React.ReactNode;
  hidePlusIcon?: boolean;
}) {
  const tabs = useAppSelector(getTabs);
  const prompts = useAppSelector(getPrompts);
  const { open, alter } = useTab();
  const navigate = useNavigate();

  const { id: paramId } = useParams() || {};
  const { pathname } = useLocation();

  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

  const handleLogout = () => {
    eraseCookie('token');
    eraseCookie('refresh_token');
    navigate('/login');
    localStorage.clear();
  };

  const handleTabClick = (id: string) => {
    alter(id);
  };

  const handleDrop = (e: DragEvent) => {
    if (e.dataTransfer === null) return;

    const id = e.dataTransfer?.getData('prompt-card');
    const prompt = prompts.find((prompt: Prompt) => prompt.id === id);

    if (!prompt) return;

    open({ ...prompt, selected: true });
  };

  return (
    <div className="flex gap-6 md:gap-10 justify-between items-center w-full h-full">
      <div className="flex h-full items-center grow">
        {/* logo */}
        <div className="w-8 md:w-[216px] h-full flex items-center mt-2">
          <Link to="/" className="hidden items-center ps-6 space-x-2 md:flex">
            <img src={Logo} alt="Prompt Studio Logo" className="w-[110px]" />
          </Link>
        </div>

        {/* tabs */}
        <div
          className="flex self-end grow "
          onDragOver={(e) => e.preventDefault()}
          onDrop={
            handleDrop as unknown as React.DragEventHandler<HTMLDivElement>
          }
        >
          {/* home tab */}
          <TabItem
            icon="home"
            active={isHomeTabActive(pathname)}
            onClick={() => navigate('/')}
          />

          {/* prompt tabs */}
          {tabs.map(({ id, name, targetTool }, index) => (
            <TabItem
              key={id}
              icon="penCircle"
              active={paramId === id}
              title={name}
              onClick={() => handleTabClick(id)}
              id={id}
              targetTool={(targetTool as Tools) || 'notSpecified'}
            />
          ))}

          {/* add tab */}
          {tabs.length <= 3 && !hidePlusIcon && (
            <TabItem icon="add" onClick={() => open()} />
          )}
        </div>
      </div>

      {/* mobile burger menu */}
      <button
        type="button"
        className="flex items-center space-x-2 md:hidden"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        {showMobileMenu ? <Icons.close /> : <Icons.menu />}
      </button>
      {showMobileMenu && (
        <MobileNav onItemClick={() => setShowMobileMenu(false)}>
          {children}
        </MobileNav>
      )}

      {/* menu drop down */}
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Icons.userCircle className="fa-2xl" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem className="border-b">
            <p className="flex flex-col gap-1">
              <span>Username</span>
            </p>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate('/settings/profile')}>
            Profile
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate('/settings/account')}>
            Settings
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={handleLogout}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
