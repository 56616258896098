import { Button } from '@src/components/ui/Button';
import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@src/components/ui/Dialog';
import { Input } from '@src/components/ui/Input';
import { cn } from '@src/utils/utils';
import { PizzaIcon } from 'lucide-react';
import { useState } from 'react';
import { useUpdateProjectMutation } from '../projectsApi';
import type { ProjectEditDialogProps } from './UpdateProjectDialog';
import { showToast } from '@src/plugins/toast';
import { getIcon } from '@src/components/icons';

const ArchiveIcon = getIcon('archive');
const PenIcon = getIcon('pen');
const MediaIcon = getIcon('media');

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & ProjectEditDialogProps;

const EditProject = ({ projectId, title, onClose, isOpen }: Props) => {
  const [updateProject, { isLoading }] = useUpdateProjectMutation();
  const [icon, setIcon] = useState('archive');
  const [name, setName] = useState(title);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name');

    try {
      const response = await updateProject({
        name: name as string,
        id: projectId as string,
        icon: icon as string,
      });
      showToast('success', 'The Project Edited Successfully');
    } catch {
      showToast('error', "Somthing wen't wrong. Please try again later.");
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold text-primary">
            Edit project
          </DialogTitle>
        </DialogHeader>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="mt-3 flex flex-row">
            <ArchiveIcon
              className={cn(
                'w-6 h-6 mr-2 cursor-pointer',
                icon === 'archive' ? 'text-indigo-600' : 'text-black'
              )}
              onClick={() => setIcon('archive')}
            />
            <PenIcon
              className={cn(
                'w-6 h-6 mr-2 cursor-pointer',
                icon === 'pen' ? 'text-indigo-600' : 'text-black'
              )}
              onClick={() => setIcon('pen')}
            />
            <MediaIcon
              className={cn(
                'w-6 h-6 mr-2 cursor-pointer',
                icon === 'media' ? 'text-indigo-600' : 'text-black'
              )}
              onClick={() => setIcon('media')}
            />
            <PizzaIcon
              className={cn(
                'w-6 h-6 mr-2 cursor-pointer',
                icon === 'pizza' ? 'text-indigo-600' : 'text-black'
              )}
              onClick={() => setIcon('pizza')}
            />
          </div>
          <DialogDescription>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
            />
          </DialogDescription>
          <DialogFooter>
            <Button type="submit" loading={isLoading}>
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditProject;
