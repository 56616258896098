import { Skeleton } from '../../../components/ui/Skeleton';

const PromptCardSkeleton = () => {
  return (
    <div className={'w-[216px] h-[320px] bg-slate-100 relative'}>
      <div className="p-0 h-[196px] bg-indigo-50 " />
      <div className="p-3">
        <Skeleton className="mb-3 bg-slate-200 w-32 h-4" />
        <Skeleton className="mb-2 bg-slate-200 w-full h-3" />
        <Skeleton className="mb-2 bg-slate-200 w-1/2 h-3" />
      </div>
      <Skeleton className="absolute w-6 h-6 rounded-full left-3 bottom-3 bg-slate-200" />
    </div>
  );
};

export default PromptCardSkeleton;
