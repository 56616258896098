import { Input, type InputProps } from '@src/components/ui/Input';

type SearchProps = InputProps;

function Search(props: SearchProps) {
  return (
    <Input
      {...props}
      type="search"
      placeholder="Search..."
      className="md:w-[100px] lg:w-[276px]"
    />
  );
}

export default Search;
