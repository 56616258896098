import type { Tools } from '@features/layout/dashboardInterface';

export const mapTargetToolToBackendKey: Record<Tools, string> = {
  notSpecified: 'NotSpecified',
  midjourney: 'Midjourney',
  dalle: 'DALL_E',
  magnific: 'Magnific',
  freepik: 'Freepik',
  firefly: 'Firefly',
  stableDiffusion: 'StableDiffusion',
  // chatgpt: "ChatGPT",
};
