import { Card, CardHeader, CardDescription } from '@src/components/ui/Card';
import { cn } from '@src/utils/utils';
import { Link } from 'react-router-dom';
import type { Project } from '../projectInterface';
import { Icons, getIcon } from '@src/components/icons';

type ProjectCardProps = React.HTMLAttributes<HTMLDivElement> & Project;

function ProjectCard({ id, icon, name, className }: ProjectCardProps) {
  const Icon = getIcon(icon);
  return (
    <Link to={`/projects/${id}`} className="relative h-40">
      <Card
        className={cn(
          'flex flex-col justify-center items-center py-10 h-full',
          className
        )}
      >
        <CardHeader className="p-2">
          {Icon ? (
            <Icon className="fill-indigo-600 w-8 h-8" />
          ) : (
            <Icons.archive className="stroke-indigo-600 w-8 h-8" />
          )}
        </CardHeader>
        <CardDescription className="font-bold text-slate-600 overflow-auto">
          {name}
        </CardDescription>
      </Card>
      <Card className="absolute -bottom-1 -z-10 w-full py-2">&nbsp;</Card>
      <Card className="absolute -bottom-2 -z-20 w-full py-2">&nbsp;</Card>
    </Link>
  );
}

export default ProjectCard;
