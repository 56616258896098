import { cn } from '@src/utils/utils';
import type { HTMLAttributes } from 'react';

function Title({
  className,
  title,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { title: string }) {
  return (
    <h3
      className={cn('text-white font-semibold text-xl', className)}
      {...props}
    >
      {title}
    </h3>
  );
}

export default Title;
