export const randomColor = (text: string) => {
  const firstChar = text.charAt(0).toLowerCase();

  if (firstChar >= 'a' && firstChar <= 'i') {
    // Pink for the first 9 characters
    return 'bg-pink-200 text-pink-400/80';
  } else if (firstChar >= 'j' && firstChar <= 'r') {
    // Sky for the next 9 characters
    return 'bg-sky-200 text-sky-400/80';
  } else {
    // Black for the rest
    return 'bg-blue-200 text-blue-400/80';
  }
};
