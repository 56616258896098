import type { Tools, Sort } from '@features/layout/dashboardInterface';
import { useAppSelector } from '@src/store';
import {
  getBulkMode,
  resetSelectedList,
} from '@src/store/features/promptSlice';
import { cn } from '@src/utils/utils';
import { useDebounce } from 'ahooks';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIcon } from '../../../components/icons';
import CreatePromptButton from './CreatePromptButton';
import FilterSelect from './FilterSelect';
import GroupAction from './GroupAction';
import PromptCardList from './PromptCardList';
import PromptView from './PromptView';
import TargetingToolSelect from './TargetingToolSelect';
import Search from './Search';

type View = 'list' | 'grid';
type Page = 'home' | 'search' | 'recent';

type PromptFilterViewProps = {
  title: string;
  page: Page;
  tool?: Tools;
};

function PromptFilterView({
  title,
  page,
  tool: defaultTool = 'notSpecified',
}: PromptFilterViewProps) {
  const defaultSort = page === 'recent' ? 'ModifiedDate' : 'CreateDate';
  const [filter, setFilter] = useState<string | undefined>();
  const [view, setView] = useState<View>('grid');
  const [sort, setSort] = useState<Sort>(defaultSort);
  const [targetingTool, setTargetingTool] = useState<Tools>(defaultTool);
  const dispatch = useDispatch();
  const isBulkMode = useAppSelector(getBulkMode) && page === 'home';

  useEffect(() => {
    return () => {
      dispatch(resetSelectedList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (defaultTool) setTargetingTool(defaultTool);
  }, [defaultTool]);

  const debouncedFilter = useDebounce(filter, { wait: 500 });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <div>
      {/* filter and serach bar */}
      {page === 'home' && (
        <div className="mb-6 flex justify-between items-center">
          <CreatePromptButton />
          <div className="hidden md:flex gap-2">
            <FilterSelect
              value={sort}
              onValueChange={(data: Sort) => setSort(data)}
            />
            <TargetingToolSelect
              value={targetingTool}
              onValueChange={(data: Tools) => setTargetingTool(data)}
              className="max-w-fit"
            />
            <Search value={filter} onChange={handleFilterChange} />
          </div>
        </div>
      )}

      {/* title and view switcher */}
      <div className="flex justify-between align-center pb-5">
        <h2 className="text-3xl font-semibold tracking-tight text-primary">
          {filter ? `Search "${filter}"` : title}
        </h2>
        {isBulkMode ? (
          <GroupAction />
        ) : (
          <div className="flex items-center bg-slate-200 h-[42px] w-[82px] p-1.5 rounded-md">
            {['grid', 'list'].map((icon) => {
              const Icon = getIcon(icon);

              return (
                <div
                  key={icon}
                  onClick={() => setView(icon as View)}
                  className={cn(
                    ' h-full rounded-md cursor-pointer grow p-0 flex items-center justify-center transition-all duration-300',
                    view === icon ? 'bg-white ' : 'transparent'
                  )}
                >
                  <Icon />
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* prompt list */}
      <PromptCardList
        sortField={sort as Sort}
        filter={debouncedFilter}
        targetTool={targetingTool}
        view={view}
        sortOrder="Descending"
      />

      {/* prompt view */}
      <PromptView />
    </div>
  );
}

export default PromptFilterView;
