import { MainNav } from '@features/layout/MainNav';
import { DashboardNav } from '@features/layout/DashboardNav';
import { Outlet } from 'react-router-dom';

function DashboardLayout() {
  return (
    <div className="flex min-h-screen flex-col">
      {/* header */}
      <header className="sticky top-0 z-40 bg-primary text-background">
        <div className="pe-6 flex h-14 items-center justify-between">
          <MainNav />
        </div>
      </header>
      <div className="grid flex-1 md:grid-cols-[216px_1fr] px-0">
        <aside className="hidden w-[216px] flex-col md:flex bg-slate-700">
          <DashboardNav />
        </aside>

        <main className="flex w-full flex-1 flex-col py-6 pe-4 ps-11 bg-slate-100">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
