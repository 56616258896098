import { useAppSelector } from '@src/store';
import { getActiveTab, getPromptPhrase } from '@src/store/features/tabsSlice';
import { cn } from '@src/utils/utils';
import { useEffect, useState } from 'react';
import useModifyPrompt from '../hooks/useModifyPrompt';
import CopyButton from './CopyButton';
import { Textarea } from '@src/components/ui/Textarea';
import { Button } from '@src/components/ui/Button';

const PhraseEditor = () => {
  const activeTab = useAppSelector(getActiveTab);
  const promptPhrase = useAppSelector(getPromptPhrase);
  const [phrase, setPhrase] = useState(activeTab?.phrase || '');
  const { modify, isLoading } = useModifyPrompt();
  const hasNewPhrase = phrase !== activeTab?.phrase;

  useEffect(() => {
    setPhrase(activeTab?.phrase || '');
  }, [activeTab])

  function handleSubmit() {
    modify({ ...activeTab, phrase });
  }

  return (
    <div className="flex flex-col gap-10">
      <div>
        <div className="flex gap-3 items-center mb-2">
          <span className="text-slate-500 text-base font-medium">
            Prompt text
          </span>
          {hasNewPhrase && <Button size='sm' className='!h-6' onClick={handleSubmit} disabled={isLoading}>Save</Button>}
        </div>
        <Textarea
          onChange={(e)=>setPhrase(e.target.value)}
          className="h-52 border-0 outline-none p-6"
          value={phrase}
        />
      </div>

      <div>
        <div className="flex items-center justify-between mb-2">
          <p className="text-slate-500 text-base font-medium ">
            Prompt Preview
          </p>
          <CopyButton />
        </div>
        <p
          className={cn(
            'bg-white rounded-md p-4 min-h-[94px]',
            promptPhrase
              ? ''
              : 'flex items-center justify-center text-slate-300'
          )}
        >
          {promptPhrase || 'Your prompt preview will show here'}
        </p>
      </div>
    </div>
  );
};

export default PhraseEditor;
