import { cn } from '@src/utils/utils';
import { noop } from 'lodash';
import { useGetGoogleLinkMutation } from './accountApi';
import Footer from './components/Footer';
import useLoginToken from './hooks/useLoginToken';
import Logo from '@assets/svg/logo/logo-white.svg';
import Google from '@assets/svg/logo/google.svg';
import { useSearchParams } from 'react-router-dom';

export default function AuthenticationPage() {
  const [getLink, { isLoading }] = useGetGoogleLinkMutation();
  const [searchParams] = useSearchParams();
  const code = searchParams?.get('code') || '';

  useLoginToken(code);

  const handleLoginWithGoogle = async () => {
    try {
      const link = await getLink().unwrap();

      window.location.href = link.data;
    } catch (error) {
      noop();
    }
  };

  if (code) return null;

  return (
    <div className="flex flex-col overflow-hidden">
      {/* main  login content */}
      <main className=" bg-slate-200 w-svw h-svh flex justify-center relative">
        <div className="lg:w-[440px] relative top-[20%]">
          <img
            src={Logo}
            alt="Prompt Studio Logo"
            className={cn('mb-20 mx-auto')}
          />
          <div className="bg-slate-300 rounded-lg flex items-center justify-center px-20 py-16">
            <button
              type="button"
              className="bg-white rounded-lg flex items-center justify-center gap-5 px-6 py-3"
              onClick={handleLoginWithGoogle}
            >
              <img
                src={Google}
                alt="Google Logo"
                className={isLoading? 'animate-zoom-play' : ''}
              />
              <p className="text-base">Sign in with Google</p>
            </button>
          </div>
        </div>
      </main>

      {/* footer */}
      <Footer />
    </div>
  );
}
