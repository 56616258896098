import { Link } from 'react-router-dom';
import Logo from '@assets/svg/logo/logo.svg';
import { socialIcons } from '@assets/svg';

const Footer = () => {
  return (
    <footer className="bg-slate-300 h-[164px] px-48 flex items-center justify-between">
      <div>
        <img src={Logo} alt="Prompt Studio Logo" />
        <div className="flex gap-6 text-xs mt-2">
          <p className="text-slate-500">
            © 2024 Prompt Studio. All rights reserved.
          </p>
          <Link to="#" className="text-slate-700">
            Terms of Service
          </Link>
          <Link to="#" className="text-slate-700">
            Privacy Policy
          </Link>
        </div>
      </div>
      <div className="flex gap-2.5">
        {['twitter', 'linkedin', 'instagram'].map((social) => (
          <Link
            to={`https://${social}.com`}
            key={social}
            className="hover:scale-110 transition-all duration-300"
          >
            <img
              src={socialIcons[social]}
              alt={social}
              width={17}
              height={17}
            />
          </Link>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
