import { cn } from '@src/utils/utils';
import { useMemo } from 'react';

function ImagePlaceholder({
  text,
}: {
  text: string;
}) {
  const firstChar = text.charAt(0).toLowerCase();
  const colorClass = useMemo(() => {
    if (firstChar >= 'a' && firstChar <= 'i') {
      // Pink for the first 9 characters
      return 'bg-pink-200 text-pink-400/80';
    } else if (firstChar >= 'j' && firstChar <= 'r') {
      // Sky for the next 9 characters
      return 'bg-sky-200 text-sky-400/80';
    } else {
      // Black for the rest
      return 'bg-blue-200 text-blue-400/80';
    }
  }, [firstChar]);

  return (
    <div
      draggable={false}
      style={{
        WebkitLineClamp: 4,
      }}
      className={cn(
        'h-full aspect-square p-3 text-4xl font-semibold text-ellipsis overflow-hidden whitespace-wrap',
        colorClass
      )}
    >
      <span
        className="overflow-hidden text-ellipsis"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          lineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {text}
      </span>
    </div>
  );
}
export default ImagePlaceholder;
