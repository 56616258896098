import { Button } from '../../../components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/Dialog';
import { Input } from '../../../components/ui/Input';

import { showToast } from '@src/plugins/toast';
import IconWrapper from '@features/projects/components/ProjectIconWrapper';
import { useAddProjectMutation } from '@features/projects/projectsApi';
import useDisclosure from '@src/hooks/useDisclosure';
import { useState } from 'react';

const icons = [
  'book',
  'building',
  'camcorder',
  'camera',
  'cart',
  'comments',
  'food',
  'music',
  'box',
];

function CreateProjectDialog() {
  const [icon, setIcon] = useState('book');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addProject, { isLoading }] = useAddProjectMutation();
  const [name, setName] = useState('');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!name) {
      return;
    }
    try {
      const response = await addProject({
        name: name as string,
        icon: icon as string,
      });
      showToast('success', 'Project created successfully');
    } catch {
      showToast('error', "Somthing wen't wrong. Please try again later.");
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        open ? onOpen() : onClose();
      }}
    >
      <Button className="bg-primary text-white" onClick={onOpen}>
        New Project
      </Button>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold text-primary">
            New project
          </DialogTitle>
        </DialogHeader>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="mt-3 flex gap-1">
            {icons.map((iconName) => (
              <IconWrapper
                key={iconName}
                isActive={icon === iconName}
                name={iconName}
                onclick={setIcon}
              />
            ))}
          </div>
          <DialogDescription>
            <Input
              placeholder="Project name..."
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoComplete="off"
            />
          </DialogDescription>
          <DialogFooter>
            <Button type="submit" loading={isLoading} disabled={!name}>
              Create
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
export default CreateProjectDialog;
