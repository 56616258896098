import { useListProjectsQuery } from '@features/projects/projectsApi';
import { useUpdatePromptProjectMutation } from '@features/prompts/promptApi';
import { noop } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Skeleton } from '../../components/ui/Skeleton';
import NavigationItem from './NavigationItem';
import type { Project } from '@features/projects/projectInterface';
import Title from '@src/components/ui/Title';
import { Icons, getIcon } from '@src/components/icons';
import { cn } from '@src/utils/utils';

export default function ProjectNav() {
  const { isLoading, data } = useListProjectsQuery();
  const [addPromptToProject] = useUpdatePromptProjectMutation();
  const naviagte = useNavigate();
  const { pathname } = useLocation();

  const handleDrop = async (e: DragEvent, projectId: string) => {
    if (e.dataTransfer === null) return;

    const promptId = e.dataTransfer?.getData('prompt-card');

    if (projectId && promptId) {
      try {
        await addPromptToProject({ promptId, projectId });
        naviagte(`/projects/${projectId}`);
      } catch (error) {
        noop();
      }
    }
  };

  // skeleton
  if (isLoading)
    return (
      <div className="flex flex-col gap-2">
        <Skeleton className="h-4 w-full bg-slate-200 bg-opacity-20" />
        <Skeleton className="h-4 w-full bg-slate-200 bg-opacity-20" />
        <Skeleton className="h-4 w-full bg-slate-200 bg-opacity-20" />
      </div>
    );

  return (
    <div>
      {/* title */}
      <div className="flex items-center justify-between mt-8 mb-2">
        <Title className="ps-4" title="Projects" />
      </div>
      <div className="flex flex-col gap-1">
        {/* last 5 projects */}
        {data?.data
          .slice(-5)
          .reverse()
          .map(({ icon, id, name }: Project) => {
            const href = `/projects/${id}`;
            const Icon = getIcon(icon);
            const isActive = pathname === href;

            return (
              <div
                key={name}
                onDragOver={(e) => e.preventDefault()}
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                onDrop={(e: any) => handleDrop(e, id)}
              >
                <Link to={href}>
                  <span
                    className={cn(
                      'group flex items-center rounded-md px-3 py-2 text-sm font-medium text-white',
                      'hover:bg-accent hover:text-accent-foreground',
                      isActive
                        ? '!bg-white hover:bg-opacity-10 !text-blue-700 child:!stroke-blue-700 '
                        : 'transparent'
                    )}
                  >
                    {Icon ? (
                      <Icon
                        className={cn(
                          'mr-2 h-4 w-4 group-hover:fill-accent-foreground fill-white',
                          isActive && '!fill-blue-700'
                        )}
                      />
                    ) : (
                      <Icons.archive className="mr-2 h-4 w-4 group-hover:stroke-accent-foreground" />
                    )}
                    <span>{name}</span>
                  </span>
                </Link>
              </div>
            );
          })}
        <NavigationItem
          title="All projects"
          href="/projects"
          icon="dotsHorizontal"
        />
      </div>
    </div>
  );
}
