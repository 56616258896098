import CreateProjectDialog from '@features/projects/components/CreateProjectDialog';
import ProjectCardList from './components/ProjectCardList';

function ProjectsList() {
  document.title = 'All Projects - Image Prompt';
  return (
    <>
      <div className="flex justify-between align-center pl-0 mb-4">
        <h2 className="text-3xl font-bold tracking-tight">All Projects</h2>
        <div>
          <CreateProjectDialog />
        </div>
      </div>
      <ProjectCardList />
    </>
  );
}

export default ProjectsList;
