import type { Tools } from '@features/layout/dashboardInterface';
import { useUpdateToolMutation } from '@features/prompts/promptApi';
import { mapTargetToolToBackendKey } from '@src/utils/targetToolsUtil';
import _, { noop } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Title from '@src/components/ui/Title';
import { getIcon } from '@src/components/icons';
import { cn } from '@src/utils/utils';
import { useAppSelector } from '@src/store';
import { getUsedTargetingTools } from '@src/store/features/promptSlice';

const ToolsNav = () => {
  const [updateTargetingTool] = useUpdateToolMutation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const usedTools = useAppSelector(getUsedTargetingTools);

  const handleDrop = async (e: DragEvent, tool: string) => {
    if (e.dataTransfer === null) return;

    const promptId = e.dataTransfer?.getData('prompt-card');

    if (tool && promptId) {
      const targetTool = mapTargetToolToBackendKey[tool as Tools];
      try {
        await updateTargetingTool({ id: promptId, targetTool });
        navigate(`/search/${tool}`);
      } catch (error) {
        noop();
      }
    }
  };

  if (usedTools.length === 0) return null;

  return (
    <div className="text-white">
      {/* title */}
      <div className="flex items-center justify-between mt-8 mb-2">
        <Title className="ps-4" title="Target Tools" />
      </div>

      {/* tools */}
      <div className="flex flex-col gap-1">
        {/* last 5 projects */}
        {usedTools.map((key) => {
          if (key === 'notSpecified') return null;
          const href = `/search/${key}`;
          const Icon = getIcon(key);
          const isActive = pathname === href;

          return (
            <div
              key={key}
              onDragOver={(e) => e.preventDefault()}
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              onDrop={(e: any) => handleDrop(e, key)}
            >
              <Link to={href}>
                <span
                  className={cn(
                    'group flex items-center rounded-md px-3 py-2 text-sm font-medium text-white',
                    'hover:bg-accent hover:text-accent-foreground',
                    isActive
                      ? '!bg-white hover:bg-opacity-10 !text-blue-700  '
                      : 'transparent'
                  )}
                >
                  <Icon className="mr-2 h-4 w-4 group-hover:color-accent-foreground" />
                  <span>{_.startCase(key)}</span>
                </span>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ToolsNav;
