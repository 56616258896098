import type { Tools } from '@features/layout/dashboardInterface';
import { cn } from '@src/utils/utils';
import { getIcon } from '../../../components/icons';

const ToolsSelector = ({
  tool,
  className,
}: { tool: Tools; className?: string }) => {
  const Icon = getIcon(tool);
  if (tool === 'notSpecified') return null;

  if (!Icon) return null;

  return <Icon className={cn('w-6 h-6 rounded-full', className)} />;
};

export default ToolsSelector;
