export function setCookie(cname: string, cvalue: string, exdays: number) {
  if (typeof window === 'undefined') return;
  const date = new Date();
  date.setTime(date.getTime() + exdays);
  const expires = 'expires=' + date.toISOString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname: string) {
  if (typeof window === 'undefined') return '';
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;';
}
