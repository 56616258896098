import MoveToProject from '@features/prompts/components/moveToProject/MoveProjects';
import { useDeleteGroupOfPromptsMutation } from '@features/prompts/promptApi';
import useDisclosure from '@src/hooks/useDisclosure';
import { showToast } from '@src/plugins/toast';
import { useAppSelector } from '@src/store';
import {
  getSelectedPrompts,
  resetSelectedList,
} from '@src/store/features/promptSlice';
import { useDispatch } from 'react-redux';
import { getIcon } from '../../../components/icons';
import { Button } from '../../../components/ui/Button';
import { noop } from 'lodash';

const Archive = getIcon('archive');
const Trash = getIcon('x');

const GroupAction = () => {
  const selectedPrompts = useAppSelector(getSelectedPrompts);
  const [deletePrompts] = useDeleteGroupOfPromptsMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const deleteAll = async () => {
    try {
      await deletePrompts(selectedPrompts).unwrap();
      showToast('success', 'prompts deleted successfully');
      dispatch(resetSelectedList());
    } catch (error) {
      noop();
    }
  };

  return (
    <div className="h-[42px] flex items-center gap-2">
      <Button
        className="flex items-center gap-2 prompt-area bg-slate-200 text-slate-700 hover:bg-slate-300"
        onClick={onOpen}
      >
        <Archive className="w-4 h-4 me-2" />
        <span>Move to Project...</span>
      </Button>
      <Button
        className="bg-slate-200 text-red-600  hover:bg-slate-300 prompt-area"
        onClick={deleteAll}
      >
        <Trash className="w-3 h-5 me-2" />
        <span>Delete</span>
      </Button>
      <MoveToProject
        onClose={onClose}
        promptGroupList={selectedPrompts}
        isOpen={isOpen}
      />
    </div>
  );
};

export default GroupAction;
