import {
  useGetPromptFullPhraseMutation,
  useDeletePromptMutation,
  useDuplicatePromptMutation,
  useFindPromptInfoMutation,
} from '@features/prompts/promptApi';
import type { Prompt } from '@features/prompts/promptInterface';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@src/components/ui/DropdownMenu';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import useDisclosure from '@src/hooks/useDisclosure';
import useTab from '@src/hooks/useTab';
import { showToast } from '@src/plugins/toast';
import { setPreview } from '@src/store/features/appSlice';
import { cn } from '@src/utils/utils';
import { noop } from 'lodash';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmDelete from '../../../components/ui/ConfirmDelete';
import { Icons } from '../../../components/icons';

export const PromptCardActions = memo(
  ({
    prompt,
    onOpen,
    className,
    align,
  }: {
    prompt: Prompt;
    onOpen: () => void;
    className?: string;
    align: 'start' | 'end';
  }) => {
    const { id, name, phrase, cover } = prompt;
    const dispatch = useDispatch();
    const { open } = useTab();
    const [getPromptPhrase] = useGetPromptFullPhraseMutation();
    const [remove] = useDeletePromptMutation();
    const [duplicate] = useDuplicatePromptMutation();
    const [findPrompt] = useFindPromptInfoMutation();
    const {
      isOpen: openConfirmDelete,
      onOpen: onOpenConfirmDelete,
      onClose: onCloseConfirmDelete,
    } = useDisclosure();

    const menuItems = [
      {
        label: 'Quick view',
        id: 1,
        className:
          'focus:bg-accent focus:text-accent-foreground flex items-center justify-between',
        icon: <Icons.eye className="mr-2 h-4 w-4" />,
        onselect: openPreview,
        shortcut: 'space',
      },
      {
        label: 'Edit...',
        id: 2,
        className:
          'focus:bg-accent focus:text-accent-foreground flex items-center justify-between',
        icon: <Icons.extrnalLink className="mr-2 h-4 w-4" />,
        onselect: () => open({ ...prompt, selected: true }),
        shortcut: '⌘E',
      },
      {
        label: 'Copy prompt text',
        id: 3,
        className:
          'focus:bg-accent focus:text-accent-foreground flex items-center justify-between',
        icon: <Icons.type className="mr-2 h-4 w-4" />,
        onselect: copyToClipboard,
        shortcut: '⌘C',
      },
      {
        label: 'Move to project...',
        id: 4,
        className:
          'focus:bg-accent focus:text-accent-foreground flex items-center justify-between',
        icon: <Icons.archive className="mr-2 h-4 w-4" />,
        onselect: onOpen,
        shortcut: '⌘M',
      },
      {
        label: 'Duplicate',
        id: 5,
        className:
          'focus:bg-accent focus:text-accent-foreground flex items-center justify-between',
        icon: <Icons.copy className="mr-2 h-4 w-4" />,
        onselect: duplicatePrompt,
        shortcut: '⌘D',
      },
      {
        label: 'Delete',
        id: 6,
        className:
          'focus:bg-accent flex items-center justify-between text-red-600',
        icon: <Icons.x className="mr-2 h-4 w-4" />,
        onselect: onOpenConfirmDelete,
      },
    ];

    async function openPreview() {
      try {
        const result = await findPrompt(id).unwrap();
        dispatch(setPreview(result));
      } catch (error) {
        noop();
      }
    }

    async function copyToClipboard() {
      try {
        const result = await getPromptPhrase(id).unwrap();
        navigator.clipboard.writeText(result).then(() => {
          showToast('success', 'Prompt text copied to clipboard');
        });
      } catch (error) {
        noop();
      }
    }

    async function deletePrompt() {
      try {
        await remove(id).unwrap();
        showToast('success', 'Prompt deleted successfully');
      } catch (error) {
        noop();
      }
    }

    async function duplicatePrompt() {
      try {
        await duplicate(id).unwrap();
        showToast('success', 'Prompt duplicated successfully');
      } catch (error) {
        noop();
      }
    }

    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <DotsHorizontalIcon
              className={cn(
                'cursor-pointer rounded-full h-6 w-6 bg-gray-200 p-1',
                className
              )}
            />
          </DropdownMenuTrigger>
          <DropdownMenuPortal
            container={
              document.getElementById(`prompt-card-${id}`) as HTMLElement
            }
          >
            <DropdownMenuContent align={align} className="w-56">
              {menuItems.map((item) => (
                <DropdownMenuItem
                  key={item.id}
                  onSelect={(e) => {
                    e.stopPropagation();
                    item.onselect();
                  }}
                  className={item.className}
                  shortcut={item.shortcut}
                >
                  <div className="flex items-center">
                    {item.icon}
                    {item.label}
                  </div>
                  <div className="text-slate-500">{item.shortcut}</div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
        <ConfirmDelete
          title="Delete prompt"
          description="Are you sure you want to delete this prompt?"
          isOpen={openConfirmDelete}
          onClose={onCloseConfirmDelete}
          onConfirm={deletePrompt}
        />
      </>
    );
  }
);
