import { useListPromptsQuery } from '@features/prompts/promptApi';
import type { PromptApi } from '@features/prompts/promptInterface';
import { useAppSelector } from '@src/store';
import { getSelectedPrompts } from '@src/store/features/promptSlice';
import { cn } from '@src/utils/utils';
import PromptCard from './PromptCard';
import PromptListView from './PromptListView';
import { PromptsSkeleton } from './PromptsSkeleton';

type PromptCardListProps = PromptApi & {
  view?: 'grid' | 'list';
};

function PromptCardList({ view = 'grid', ...rest }: PromptCardListProps) {
  const { isLoading, data } = useListPromptsQuery(rest);
  const selectedPrompts = useAppSelector(getSelectedPrompts);
  const gridClass =
    view === 'grid' ? 'flex gap-x-4 flex-wrap justify-start' : 'flex flex-col';

  if (isLoading || !data) return <PromptsSkeleton />;

  if (data.items.length === 0)
    return <p className="text-lg text-muted-foreground">No prompts found</p>;

  return (
    <div className={cn('gap-y-3 ', gridClass)}>
      {data.items.map((prompt) => {
        const isSelected = selectedPrompts.includes(prompt.id);

        if (view === 'list') {
          return (
            <PromptListView
              prompt={prompt}
              key={prompt.id}
              isSelected={isSelected}
            />
          );
        }
        return (
          <PromptCard key={prompt.id} prompt={prompt} isSelected={isSelected} />
        );
      })}
    </div>
  );
}

export default PromptCardList;
