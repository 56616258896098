import { useState } from 'react';

function useDisclosure() {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const onToggle = () => setIsOpen((prev) => !prev);
  return {
    onChange: setIsOpen,
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
}

export default useDisclosure;
