import type { Token } from '@features/login/accountInterface';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import type { ApiResponse } from '@src/types/publicTypes';
import { getCookie, setCookie } from '@src/utils/cookieUtils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl =
  process.env.NEXT_PUBLIC_API_URL || 'https://api.promptstudio.app';

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getCookie('token');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const appFetchBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (!result.error) return result;

  if (result.error && result.error.status === 401) {
    const refresh = getCookie('refresh_token');
    const refreshResult = await baseQuery(
      `/account/token/refresh?refreshToken=${refresh}`,
      api,
      extraOptions
    );
    if (!refreshResult.data) window.location.replace('/login');

    const token = refreshResult?.data as ApiResponse<Token>;

    if (!token || !token.success) window.location.replace('/login');

    setCookie(
      'token',
      token.data.accessToken,
      Math.floor(token.data.expiresIn / (3600 * 24))
    );
    setCookie('refresh_token', token.data.refreshToken, token.data.expiresIn);
    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: appFetchBaseQuery,
  tagTypes: ['Project', 'Prompt', 'Assets'],
  endpoints: () => ({}),
});
