import * as React from 'react';
import type { SVGProps } from 'react';

const ChatGpt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={17}
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0625 14.1914C17.0625 14.1211 17.0273 14.0859 16.9922 14.0156L9.75 2.44922L2.47266 14.0156C2.4375 14.0859 2.4375 14.1211 2.4375 14.1914C2.4375 14.332 2.54297 14.4375 2.68359 14.4375H16.7812C16.9219 14.4375 17.0625 14.332 17.0625 14.1914ZM0.75 14.1914C0.75 13.8047 0.855469 13.4531 1.03125 13.1367L8.66016 0.972656C8.87109 0.621094 9.29297 0.375 9.75 0.375C10.1719 0.375 10.5938 0.621094 10.8047 0.972656L15.2344 8.03906L16.9219 5.26172C17.0977 5.05078 17.3438 4.875 17.625 4.875C17.8711 4.875 18.1523 5.05078 18.293 5.26172L22.9336 12.8906C23.1094 13.207 23.25 13.5938 23.25 13.9805C23.25 15.1758 22.2656 16.125 21.0703 16.125H16.7812H2.68359C1.62891 16.125 0.75 15.2461 0.75 14.1914Z"
      fill={'white'}
    />
  </svg>
);
export default ChatGpt;
