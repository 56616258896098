import { cn } from '@src/utils/utils';
import type { LucideIcon } from 'lucide-react';

type ActionItemProps = {
  variant: 'default' | 'destructive';
  icon: LucideIcon;
  label: string;
  onClick?: () => void;
  className?: string;
};
function ActionItem({
  label,
  icon: Icon,
  variant,
  onClick,
  className,
}: ActionItemProps) {
  return (
    <span
      onClick={onClick}
      className={cn(
        'group flex items-center rounded-md px-1 py-2 text-sm font-medium text-destructive hover:bg-slate-50 cursor-pointer',
        variant === 'default' && 'text-slate-600 hover:text-accent-foreground',
        className
      )}
    >
      <Icon className="mr-2 h-4 w-4" />
      <span>{label}</span>
    </span>
  );
}
export default ActionItem;
