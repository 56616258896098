import type { Tools } from '@features/layout/dashboardInterface';
import ImagePlaceholder from '@features/prompts/components/ImagePlaceholder';
import MoveToProject from '@features/prompts/components/moveToProject/MoveProjects';
import type { Prompt } from '@features/prompts/promptInterface';
import useDisclosure from '@src/hooks/useDisclosure';
import useDragAndDrop from '@src/hooks/useDragAndDrop';
import useOutsideClick from '@src/hooks/useOutsideClick';
import useSelectedPrompt from '@src/hooks/useSelectedPrompt';
import useTab from '@src/hooks/useTab';
import { resetSelectedList } from '@src/store/features/promptSlice';
import { cn } from '@src/utils/utils';
import { useRef, type DragEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from '../../../components/ui/Card';
import ToolsSelector from './ToolsSelector';
import { PromptCardActions } from './PromptCardActions';
import { randomColor } from './getRandomColor';

function PromptListView({
  prompt,
  isSelected,
}: { prompt: Prompt; isSelected: boolean }) {
  const { name, cover, phrase, targetTool, id } = prompt;
  const colorClass = randomColor(phrase || name);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onDragStart, onDragEnd, isDragging } = useDragAndDrop(
    id,
    cover ?? '',
    colorClass
  );
  const { handleSelectPrompt } = useSelectedPrompt(id);
  const { open } = useTab();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const clickHandler = (element: EventTarget) => {
    const closestPromptCard = !!(element as HTMLElement).closest(
      '.prompt-area'
    );

    if (closestPromptCard) return;
    dispatch(resetSelectedList());
  };

  useOutsideClick(ref, clickHandler);

  const image = cover ? (
    <img
      className="w-full h-full object-cover "
      alt={`prompt cover for ${name}`}
      src={cover}
      draggable={false}
    />
  ) : (
    <ImagePlaceholder text={phrase || name} />
  );

  return (
    <>
      <Card
        className={cn(
          'prompt-area bg-white rounded-md p-2 flex h-[80px] items-center w-full gap-4 group',
          'border-none cursor-pointer shadow-xs transition-all duration-200 ease-in-out',
          isDragging && 'opacity-20 ',
          isSelected && 'ring-2 ring-offset-0 ring-slate-500'
        )}
        id={`prompt-card-${id}`}
        draggable
        onDragStart={onDragStart as unknown as DragEventHandler<HTMLDivElement>}
        onDragEnd={onDragEnd}
        onDoubleClick={() => {
          open({ ...prompt, selected: true });
        }}
        onClick={handleSelectPrompt}
        ref={ref}
      >
        <div className="w-16 h-16 rounded-lg shrink-0 overflow-hidden">
          {' '}
          {image}
        </div>

        <div className="grow">
          <p className="text-primary font-semibold text-xl mb-1 line-clamp-1">
            {prompt.name}
          </p>
          <p className="text-slate-500 text-sm line-clamp-1">{prompt.phrase}</p>
        </div>
        <div className="flex gap-3 items-center px-1">
          <PromptCardActions
            prompt={prompt}
            onOpen={onOpen}
            align="end"
            className={cn(
              'opacity-0 group-hover:opacity-100',
              isSelected && 'opacity-100'
            )}
          />
          <ToolsSelector
            tool={targetTool.replace('_', '').toLowerCase() as Tools}
          />
        </div>
      </Card>

      <MoveToProject onClose={onClose} id={id} isOpen={isOpen} />
    </>
  );
}

export default PromptListView;
