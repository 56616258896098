import Spinner from '../../../components/ui/Spinner';

function ThumbnailSkeleton() {
  return (
    <div className="w-[100px] h-[100px]">
      <div className="flex items-center justify-center border-dashed rounded-md border-2 py-3 w-full h-full">
        <Spinner />
      </div>
    </div>
  );
}
export default ThumbnailSkeleton;
