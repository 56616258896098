import { ToastContainer } from 'react-toastify';

import Router from './router/Router';

const App = () => (
  <>
    <Router />
    <ToastContainer />
  </>
);

export default App;
