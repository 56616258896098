import type { Tools } from '@features/layout/dashboardInterface';
import PromptFilterView from '@features/prompts/components/PromptFilterView';
import { mapTargetToolToBackendKey } from '@src/utils/targetToolsUtil';
import { useParams } from 'react-router-dom';

function SearchPage() {
  const { tool } = useParams();
  document.title = `${mapTargetToolToBackendKey[tool as Tools]} Prompts`;

  if (!mapTargetToolToBackendKey[tool as Tools])
    return (
      <h1 className="text-2xl font-semibold">
        The entered targeting tool is not valid
      </h1>
    );

  return (
    <PromptFilterView
      title={`Prompts of ${mapTargetToolToBackendKey[tool as Tools]}`}
      page="search"
      tool={tool as Tools}
    />
  );
}

export default SearchPage;
