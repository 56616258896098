import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGoogleLoginMutation } from '../accountApi';
import { setCookie } from '@src/utils/cookieUtils';
import { noop } from 'lodash';

const useLoginToken = (code: string) => {
  const [googleLogin] = useGoogleLoginMutation();
  const navigate = useNavigate();

  useEffect(() => {
    async function login() {
      try {
        const response = await googleLogin(code).unwrap();
        setCookie('token', response.data.accessToken, response.data.expiresIn);
        setCookie(
          'refresh_token',
          response.data.refreshToken,
          response.data.expiresIn
        );
        setCookie('id_token', response.data.idToken, response.data.expiresIn);
        navigate('/');
      } catch (err) {
        noop();
      }
    }

    if (code) login();
  }, [code, googleLogin, navigate]);
};

export default useLoginToken;
