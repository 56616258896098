import * as React from 'react';
import type { SVGProps } from 'react';
const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={16} height={16} rx={8} fill="#F4F4F5" />
    <path
      d="M7.49926 10.983C7.49926 11.0839 7.41748 11.1657 7.31659 11.1657H5.51593C5.41504 11.1657 5.33326 11.0839 5.33326 10.983V10.4317C5.33259 10.331 5.41459 10.249 5.51526 10.249H7.31593C7.41659 10.249 7.49859 10.331 7.49859 10.4317V10.9823L7.49926 10.983ZM10.9833 6.66567C11.0841 6.66567 11.1659 6.58389 11.1659 6.48301V5.51567C11.1659 5.41479 11.0841 5.33301 10.9833 5.33301H5.51593C5.41504 5.33301 5.33326 5.41479 5.33326 5.51567V9.56701C5.33326 9.66834 5.41526 9.74967 5.51593 9.74967H7.31593C7.41681 9.74967 7.49859 9.66789 7.49859 9.56701V9.09901C7.49859 8.99767 7.58059 8.91634 7.68126 8.91634H10.9826C11.0835 8.91634 11.1653 8.83456 11.1653 8.73367V7.76501C11.1653 7.66438 11.0839 7.58271 10.9833 7.58234H7.68259C7.58171 7.58234 7.49993 7.50056 7.49993 7.39967V6.84901C7.49993 6.74834 7.58193 6.66634 7.68259 6.66634H10.9826L10.9833 6.66567Z"
      fill="black"
    />
  </svg>
);
export default SVGComponent;
