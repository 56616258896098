import { getIcon } from '@src/components/icons';
import { cn } from '@src/utils/utils';

const IconWrapper = ({
  isActive,
  name,
  onclick,
}: {
  isActive: boolean;
  name: string;
  onclick: (a: string) => void;
}) => {
  const Icon = getIcon(name);

  return (
    <div
      className={cn(
        'child:w-6 child:h-6 mr-2 cursor-pointer transition-all duration-300 hover:scale-125',
        isActive ? 'scale-125 fill-blue-500' : 'text-black'
      )}
      onClick={() => onclick(name)}
      title={name}
    >
      <Icon />
    </div>
  );
};

export default IconWrapper;
