import Layout from '@features/layout/DashboardLayout';
import DevLogin from '@features/login/DevLogin';
import Login from '@features/login/Login';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import PromptsList from '@features/prompts/PromptsList';
import RecentPage from '@features/recent/RecentPage';
import ProjectsList from '@features/projects/ProjectsList';
import SingleProject from '@features/projects/SingleProject';
import SearchPage from '@features/search/SearchPage';
import PromptLayout from '@features/prompts/PromptLayout';
import SinglePrompt from '@features/prompts/SinglePrompt';
import SettingsLayout from '@features/settings/SettingsLayout';
import Profile from '@features/settings/profile/Profile';
import Account from '@features/settings/account/Account';

function Index() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/devlogin" element={<DevLogin />} />

      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<PromptsList />} />
          <Route path="/recent" element={<RecentPage />} />
          <Route path="/projects" element={<ProjectsList />} />
          <Route path="/projects/:id" element={<SingleProject />} />
          <Route path="/search/:tool" element={<SearchPage />} />
        </Route>
        <Route element={<PromptLayout />}>
          <Route path="prompt/:id" element={<SinglePrompt />} />
        </Route>
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="profile" element={<Profile />} />
          <Route path="account" element={<Account />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Index;
