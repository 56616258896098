import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/main.css';
import './assets/styles/fontawesome.css';
import './assets/styles/brands.css';
import './assets/styles/solid.css';
import { store } from './store';

const isDev = process.env.NODE_ENV === 'development';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// Hot module replacement
if (isDev && module.hot) module.hot.accept();
