import type { Prompt, ImagePrompt } from '@features/prompts/promptInterface';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

type AppState = {
  preview: Prompt | null;
  imagePreview: ImagePrompt | null;
};
const initialState: AppState = {
  preview: null,
  imagePreview: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPreview: (state, action: PayloadAction<AppState['preview']>) => {
      state.preview = action.payload;
    },
    dismissPreview: (state) => {
      state.preview = null;
    },
    setImagePreview: (state, action: PayloadAction<ImagePrompt>) => {
      state.imagePreview = action.payload;
    },
    dismissImagePreview: (state) => {
      state.imagePreview = null;
    },
  },
  selectors: {
    selectImagePreview: (state) => state.imagePreview,
  },
});

export const {
  setPreview,
  dismissPreview,
  dismissImagePreview,
  setImagePreview,
} = appSlice.actions;

export const { selectors: appSelectors } = appSlice;
export default appSlice.reducer;
