import type { Prompt } from '@features/prompts/promptInterface';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';
import type { Tools } from '@features/layout/dashboardInterface';

type InitialState = {
  prompts: Array<Prompt>;
  selectedPrompts: Array<string>;
  usedTargetingTools: Array<Tools>;
};

const initialState: InitialState = {
  prompts: [],
  selectedPrompts: [],
  usedTargetingTools: [],
};

const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setPrompts(state, action: PayloadAction<Prompt[]>) {
      state.prompts = action.payload;
    },
    addPromptToSelectedList(state, action: PayloadAction<string>) {
      state.selectedPrompts = [...state.selectedPrompts, action.payload];
    },
    removePromptFromSelectedList(state, action: PayloadAction<string>) {
      state.selectedPrompts = state.selectedPrompts.filter(
        (promptId) => promptId !== action.payload
      );
    },
    resetSelectedList(state) {
      state.selectedPrompts = [];
    },
    updateSelectedList(state, action: PayloadAction<string>) {
      state.selectedPrompts = [action.payload];
    },
    updateUsedTargetingTools(state, action: PayloadAction<Tools[]>) {
      const newTools = [...state.usedTargetingTools, ...action.payload];
      state.usedTargetingTools = Array.from(new Set(newTools));
    },
  },
});

const getPrompts = (state: RootState) => state.prompt.prompts;

const getSelectedPrompts = (state: RootState) => state.prompt.selectedPrompts;

const getBulkMode = (state: RootState) =>
  state.prompt.selectedPrompts.length > 1;

const getUsedTargetingTools = (state: RootState) =>
  state.prompt.usedTargetingTools;

export { getPrompts, getSelectedPrompts, getBulkMode, getUsedTargetingTools };

export const {
  setPrompts,
  addPromptToSelectedList,
  removePromptFromSelectedList,
  resetSelectedList,
  updateSelectedList,
  updateUsedTargetingTools,
} = promptSlice.actions;

export default promptSlice;
